import { verifyCheckInDays, verifyDates } from "./firebase";
import axios from 'axios';

const currentYear = new Date().getFullYear();
const nextYear = currentYear + 1;


export const validateEmail = (email: string) => {
  return email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
};

export const getDisabledDays = async (setDisabledDays) => {
  const allDates = await verifyDates();

  const nextYearDates = allDates.nextYearDates;
  const currentYearDates = allDates.currentYearDates;

  if (currentYearDates)
    for (let i = 1; i <= 12; i++) {
      currentYearDates[i]?.forEach((day: number) =>
        setDisabledDays((prevState) => {
          let temp = [...prevState];
          temp.push({ year: currentYear, month: i, day: day });
          return temp;
        }),
      );
    }

  if (nextYearDates)
    for (let i = 1; i <= 12; i++) {
      nextYearDates[i]?.forEach((day: number) =>
        setDisabledDays((prevState) => {
          let temp = [...prevState];
          temp.push({ year: nextYear, month: i, day: day });
          return temp;
        }),
      );
    }
};


export const getCheckInDays = async (setCheckInDays) => {
  const allDates = await verifyCheckInDays();

  const nextYearDates = allDates.nextYearDates;
  const currentYearDates = allDates.currentYearDates;

  if (currentYearDates)
    for (let i = 1; i <= 12; i++) {
      currentYearDates[i]?.forEach((day: number) =>
        setCheckInDays((prevState) => {
          let temp = [...prevState];
          temp.push({ year: currentYear, month: i, day: day });
          return temp;
        }),
      );
    }

  if (nextYearDates)
    for (let i = 1; i <= 12; i++) {
      nextYearDates[i]?.forEach((day: number) =>
        setCheckInDays((prevState) => {
          let temp = [...prevState];
          temp.push({ year: nextYear, month: i, day: day });
          return temp;
        }),
      );
    }
};

const conversionsApi = () => {
  const pixelApiKey = process.env.REACT_APP_FB_PIXEL;
  const accessToken = process.env.REACT_APP_ACCESS_TOKEN;
  axios.post(
    `https://graph.facebook.com/v12.0/1168387150692809/events?access_token=EAAZAPApg6420BANGqGYRzkCxvk2T4HFVPIHbuEMBFxzzMUU2j4CxZA52ZCfTctPpHuQeN9QTZBLPYFO2vci4BDQ6XFnObxPTTGnVQVOGMlYNGG9kvAmPa2S34JQZC9BHxeBnyoz9fYrtPwk36UjJDrRRZBtamlKc5uS8sYiCfiq9vLdh8xyjCw`,
    {
      data: [
        {
          event_name: 'Pageview',
          event_time: Date.now(),
          action_source: 'website',
          event_source_url: null,
          user_data: {
            em: [null],
            ph: [null],
            ge: [null],
            fbp: null,
            country: [null],
            db: [null],
          },
        },
      ],
    }
  ).then((resp) => console.log("respp", resp)).catch((error) => console.log(error, 'erooor'));
};

export default conversionsApi;