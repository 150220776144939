import { useTranslation } from 'react-i18next';

import GalleryComponent from '../components/GalleryComponent';
import TheSeparator from '../components/TheSeparator';
import HeaderMessage from '../components/HeaderMessage';

import styles from './GalleryCarousel.module.scss';


const GalleryCarousel = () => {
  const { t } = useTranslation();
  return (
    <>
      <TheSeparator anchorID="gallery" />
      <div className={styles.blockContainer}>
        <div className={styles.innerContainer}>
          <HeaderMessage headTitle={t('gallerySection.headerTitle')} headDescription={t('gallerySection.smallDescription')} />
          <div className={styles.viewComponent}></div>
          <GalleryComponent />
        </div>
      </div>
    </>
  );
};

export default GalleryCarousel;
