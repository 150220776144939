import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Calendar, Day, DayRange, DayValue } from 'react-modern-calendar-datepicker';
import { useTranslation } from 'react-i18next';

import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import styles from './../components/CheckinComponent.module.scss';
import { personsList } from './../data/arrays';
import { loadStripe } from '@stripe/stripe-js';

import images from './../data/images';

import BookingComponent from './BookingComponent';
import PaymentSuccessful from './PaymentSuccessful';
import PersonalDetails from './PersonalDetailsComponent';
import PaymentComponent from './PaymentComponent';
import { getCheckInDays, getDisabledDays } from '../functions/helpers';
import { Elements } from '@stripe/react-stripe-js';
import ReactPixel from 'react-facebook-pixel';

const stripePromise = loadStripe(`${process.env.REACT_APP_PAYMENT_KEY}`);

interface CustomisedDays {
  year: number;
  month: number;
  day: number;
  className: any;
}

const CheckinComponent = () => {
  const [showCheckInCalendar, setShowCheckInCalendar] = useState<boolean>(false);
  const [showCheckOutCalendar, setShowCheckOutCalendar] = useState<boolean>(false);
  const [showPersonsList, setShowPersonsList] = useState<boolean>(false);
  const [showBooking, setShowBooking] = useState<boolean>(false);
  const [showPersonal, setShowPersonal] = useState<boolean>(false);
  const [showPayment, setShowPayment] = useState<boolean>(false);
  const [showPaymentField, setShowPaymentField] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [personalData, setPersonalData] = useState<boolean>(false);
  const [newsChecked, setNewsChecked] = useState<boolean>(false);
  const [otherDetails, setOtherDetails] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [countryPre, setCountryPre] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [fiscalCode, setFiscalCode] = useState<string>('');
  const [registrationNumber, setRegistrationNumber] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [county, setCounty] = useState<string>('');
  const [checkOutString, setCheckOutString] = useState<string>('');
  const [type, setType] = useState<string>('individual');
  const [checkInString, setCheckInString] = useState<string>('');
  const [disabledDays, setDisabledDays] = useState<Day[]>([]);
  const [checkInDates, setCheckInDates] = useState<DayRange>({
    from: null,
    to: null,
  });
  const [checkInDays, setCheckInDays] = useState<Day[]>([]);
  const [checkInDaysCustomised, setCheckInDaysCustomised] = useState<CustomisedDays[]>([]);
  const [reservationObject, setReservationObject] = useState({});
  const [checkOutDate, setCheckOutDate] = useState<DayValue>(null);
  const currentDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  const [persons, setPersons] = useState<number>(1);

  const { t } = useTranslation();

  const resetStates = () => {
    setName('');
    setEmail('');
    setOtherDetails('');
    setPersons(1);
    setCheckInString('');
    setCheckOutString('');
    setCheckInDates({ from: null, to: null });
  };

  const sendAnalyticsForNextPress = () => {
    ReactGA.event('Filled booking form', { user_name: name, user_email: email });
  };
  const sendAnalyticsForPayPress = () => {
    ReactGA.event('Pressed pay button');
  };
  const sendAnalyticsForPaymentSuccesfull = () => {
    ReactGA.event('Payment Succesfull');
  };
  const sendAnalyticsForClosePaymentConfirmation = () => {
    ReactGA.event('Payment Pop-up close');
  };
  const sendAnalyticsForPaymentFailed = () => {
    ReactGA.event('Payment Failed');
  };

  const getDatesBetweenDates = useCallback(() => {
    const startDate = checkInDates.from?.year + '-' + checkInDates.from?.month + '-' + checkInDates.from?.day;
    const endDate = checkInDates.to?.year + '-' + checkInDates.to?.month + '-' + checkInDates.to?.day;
    let dates: any = [];
    const lastDate = new Date(endDate);
    const theDate = new Date(startDate);
    while (theDate < lastDate) {
      dates = [...dates, new Date(theDate).toLocaleDateString('en-US')];
      theDate.setDate(theDate.getDate() + 1);
    }
    const datesArray: Day[] = dates.map((element) => {
      const tempArray = element.split('/');
      return { day: parseInt(tempArray[1]), month: parseInt(tempArray[0]), year: parseInt(tempArray[2]) };
    });
    checkInDays.map((element) => {
      datesArray.map((item) => {
        if (element.day === item.day && element.month === item.month && element.year === item.year) {
          setCheckInDates({ from: null, to: null });
        }
      });
    });
  }, [checkInDates]);

  const customizeCheckInDays = async () => {
    await getCheckInDays(setCheckInDays);
    const customizedDays = checkInDays.map((element) => {
      const item = { ...element, className: styles.checkInOnly };
      return item;
    });
    const customisedDisabled = disabledDays.map((element) => {
      const item = { ...element, className: styles.disabledDays };
      return item;
    });
    const customisedArray = customizedDays.concat(customisedDisabled);
    setCheckInDaysCustomised(customisedArray);
  };

  const checkBookingDays = useCallback(() => {
    if (checkInDates.from && checkInDates.to) {
      const endDate = checkInDates.to?.year + '-' + checkInDates.to?.month + '-' + checkInDates.to?.day;
      if (
        checkInDates.from.day === checkInDates.to.day &&
        checkInDates.from.month === checkInDates.to.month &&
        checkInDates.from.year === checkInDates.to.year
      ) {
        const endDateInMs = new Date(endDate);
        const newEndDate = endDateInMs.setDate(endDateInMs.getDate() + 1);
        const newEndDateFormatted = new Date(newEndDate);
        const newEndDateString = newEndDateFormatted.toLocaleDateString('en-US');
        const newEndDateArray = newEndDateString.split('/');
        const nextDayDisabled = disabledDays.some(
          (element) =>
            element.day === parseInt(newEndDateArray[1]) &&
            element.month === parseInt(newEndDateArray[0]) &&
            element.year === parseInt(newEndDateArray[2]),
        );
        if (!nextDayDisabled)
          setCheckInDates({
            ...checkInDates,
            to: {
              day: parseInt(newEndDateArray[1]),
              month: parseInt(newEndDateArray[0]),
              year: parseInt(newEndDateArray[2]),
            },
          });
        else setCheckInDates({ from: null, to: null });
      }
    }
  }, [checkInDates]);

  const renderPersonList = () => {
    const reversedList = [...personsList].reverse();
    return (
      <>
        {window.scrollY < 300
          ? reversedList.map((item, index) => (
              <div
                className={persons === Number(item.charAt(0)) ? styles.personListTextSelected : styles.personListText}
                onClick={() => {
                  setPersons(Number(item.charAt(0)));
                  setShowPersonsList(false);
                }}
              >
                {item}
              </div>
            ))
          : personsList.map((item, index) => (
              <div
                className={persons === index + 1 ? styles.personListTextSelected : styles.personListText}
                onClick={() => {
                  setPersons(index + 1);
                  setShowPersonsList(false);
                }}
              >
                {item}
              </div>
            ))}
      </>
    );
  };

  const closeCalendar = () => {
    setShowCheckInCalendar(false);
    setShowCheckOutCalendar(false);
    setShowPersonsList(false);
  };

  const closeBooking = () => {
    setShowBooking(false);
    setShowPersonal(false);
    setShowPayment(false);
  };

  const showCheckIn = () => {
    setShowCheckInCalendar(!showCheckInCalendar);
    setShowCheckOutCalendar(false);
    setShowPersonsList(false);
  };

  const showCheckOut = () => {
    setShowCheckOutCalendar(!showCheckOutCalendar);
    setShowCheckInCalendar(false);
    setShowPersonsList(false);
  };

  const showPersonList = () => {
    setShowPersonsList(!showPersonsList);
    setShowCheckInCalendar(false);
    setShowCheckOutCalendar(false);
  };

  const showBookingHandler = () => {
    setShowBooking(true);
    setShowCheckInCalendar(false);
    setShowCheckOutCalendar(false);
    setShowPersonsList(false);
  };

  useEffect(() => {
    if (checkInDaysCustomised.length < 1) customizeCheckInDays();
  }, [showCheckInCalendar, showCheckOutCalendar, disabledDays]);

  useEffect(() => {
    getDatesBetweenDates();
    getDisabledDays(setDisabledDays);
    checkBookingDays();
    if (checkInDates.to !== null)
      ReactGA.event('Selected Dates', {
        item_name: `${checkInDates.from?.day}.${checkInDates.from?.month}-${checkInDates.to?.day}.${checkInDates.to?.month}`,
      });
  }, [checkInDates]);

  return (
    <>
      {showCheckInCalendar || showCheckOutCalendar || showPersonsList ? (
        <div onClick={closeCalendar} className={styles.backdropBackground}></div>
      ) : null}
      {showBooking || showPersonal || showPayment || showPaymentField ? (
        <div onClick={closeBooking} className={styles.backdropBooking}></div>
      ) : null}
      {showBooking && (
        <div className={styles.bookingComponent}>
          <BookingComponent
            type={type}
            setType={setType}
            setShowBooking={setShowBooking}
            sendAnalyticsForNext={sendAnalyticsForNextPress}
            checkInDates={checkInDates}
            checkOutDate={checkOutDate}
            setCheckInDates={setCheckInDates}
            setCheckOutDate={setCheckOutDate}
            persons={persons}
            checkInDaysCustomised={checkInDaysCustomised}
            setPersons={setPersons}
            setShowPersonal={setShowPersonal}
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            adress={address}
            setAdress={setAddress}
            companyName={companyName}
            setCompanyName={setCompanyName}
            fiscalCode={fiscalCode}
            setFiscalCode={setFiscalCode}
            registrationNumber={registrationNumber}
            setRegistrationNumber={setRegistrationNumber}
            city={city}
            setCity={setCity}
            county={county}
            setCounty={setCounty}
            otherDetails={otherDetails}
            setOtherDetails={setOtherDetails}
            checkOutString={checkOutString}
            setCheckOutString={setCheckOutString}
            checkInString={checkInString}
            setCheckInString={setCheckInString}
            setReservationObject={setReservationObject}
            setNewsChecked={setNewsChecked}
            newsChecked={newsChecked}
            personalData={personalData}
            setPersonalData={setPersonalData}
            countryPre={countryPre}
            setCountryPre={setCountryPre}
          />

          <div className={styles.rightStyle}></div>
          {showCheckInCalendar === true && (
            <div
              onMouseEnter={() => setShowCheckInCalendar(true)}
              onMouseLeave={() =>
                setTimeout(() => {
                  setShowCheckInCalendar(false);
                }, 300)
              }
              className={styles.calendarDown}
            >
              <Calendar
                value={checkInDates}
                onChange={setCheckInDates}
                colorPrimary={'#CAA987'}
                colorPrimaryLight={'#F0EAE4'}
                minimumDate={currentDate}
                disabledDays={disabledDays}
                customDaysClassName={checkInDaysCustomised}
              />
            </div>
          )}
        </div>
      )}
      {showPersonal && (
        <div className={styles.bookingComponent}>
          <Elements stripe={stripePromise}>
            <PersonalDetails
              setShowPersonal={setShowPersonal}
              sendAnalyticsForPayPress={sendAnalyticsForPayPress}
              setShowBooking={setShowBooking}
              persons={persons}
              name={name}
              setName={setName}
              email={email}
              setEmail={setEmail}
              adress={address}
              setAdress={setAddress}
              companyName={companyName}
              setCompanyName={setCompanyName}
              fiscalCode={fiscalCode}
              setFiscalCode={setFiscalCode}
              registrationNumber={registrationNumber}
              setRegistrationNumber={setRegistrationNumber}
              city={city}
              setCity={setCity}
              county={county}
              setCounty={setCounty}
              checkOutString={checkOutString}
              checkInString={checkInString}
              reservationObject={reservationObject}
              setOtherDetails={setOtherDetails}
              setShowPayment={setShowPaymentField}
              resetStates={resetStates}
              type={type}
              address={address}
              countryPre={countryPre}
            />
          </Elements>
        </div>
      )}

      {showPayment && (
        <div className={styles.bookingComponent}>
          <PaymentSuccessful
            sendAnalyticsForClosePaymentConfirmation={sendAnalyticsForClosePaymentConfirmation}
            setShowPayment={setShowPayment}
          />
        </div>
      )}
      {showPaymentField && (
        <div className={styles.bookingComponent}>
          <PaymentComponent
            sendAnalyticsForPaymentFailed={sendAnalyticsForPaymentFailed}
            sendAnalyticsForPaymentSuccesfull={sendAnalyticsForPaymentSuccesfull}
            reservationObject={reservationObject}
            setShowPaymentField={setShowPaymentField}
            setShowPayment={setShowPayment}
            newsChecked={newsChecked}
          />
        </div>
      )}
      <div className={styles.bottomPanel}>
        <div className={styles.extenderWrapper}>
          <div className={styles.centerer}>
            <div onClick={showCheckIn}>
              <span className={styles.textCheck}>{t('checkinComponent.checkIn')}</span>
              <img className={styles.arrow} src={images.checkInComponent.arrowDown} />
            </div>
            <div className={styles.rightStyle}></div>
            {showCheckInCalendar === true && (
              <div className={window.scrollY > 300 ? styles.calendarDown : styles.calendarUp}>
                <Calendar
                  value={checkInDates}
                  onChange={setCheckInDates}
                  colorPrimary={'#CAA987'}
                  customDaysClassName={checkInDaysCustomised}
                  colorPrimaryLight={'#F0EAE4'}
                  disabledDays={disabledDays}
                  minimumDate={currentDate}
                />
              </div>
            )}
          </div>
          <div className={styles.centerer}>
            <div onClick={showCheckOut}>
              <span className={styles.textCheck}>{t('checkinComponent.checkout')}</span>
              <img className={styles.arrow} src={images.checkInComponent.arrowDown} />
            </div>
            <div className={styles.rightStyle} />
            {showCheckOutCalendar === true && (
              <div className={window.scrollY > 300 ? styles.calendarDown : styles.calendarUp}>
                <Calendar
                  value={checkInDates}
                  onChange={setCheckInDates}
                  colorPrimary={'#CAA987'}
                  colorPrimaryLight={'#F0EAE4'}
                  disabledDays={disabledDays}
                  customDaysClassName={checkInDaysCustomised}
                  minimumDate={currentDate}
                />
              </div>
            )}
          </div>
          <div className={styles.centerer}>
            <span className={persons === 1 ? styles.textCheck : styles.textCheckMargin} onClick={showPersonList}>
              {persons} {persons === 1 ? t('checkinComponent.person') : t('checkinComponent.personsNumber')}
            </span>
            {showPersonsList && (
              <div className={window.scrollY > 300 ? styles.personsListDown : styles.personsListUp}>{renderPersonList()}</div>
            )}
            <div className={styles.flexColumn}>
              <img
                className={styles.arrow}
                src={images.checkInComponent.arrowUp}
                onClick={() => (persons < 4 ? setPersons(persons + 1) : null)}
              />
              <img
                className={styles.topper}
                src={images.checkInComponent.arrowDown}
                onClick={() => (persons > 1 ? setPersons(persons - 1) : null)}
              />
            </div>
          </div>
        </div>

        <button
          className={styles.bookAction}
          onClick={() => {
            showBookingHandler();
            if (process.env.REACT_APP_ENV !== 'test') ReactPixel.fbq('track', 'Lead');
          }}
        >
          <span className={styles.bookText}>{t('checkinComponent.bookButton')}</span>
        </button>
      </div>
    </>
  );
};

export default CheckinComponent;
