import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import TheSeparator from '../components/TheSeparator';
import HeaderMessage from '../components/HeaderMessage';

import styles from './ContactUs.module.scss';
import images from './../data/images';

import { sendEmailToOasis, sendEmailToOasisTest } from '../services/emails';

import { saveEmail } from '../services/emails';
import { oasisEmail, phoneNumber } from '../constants/constants';

const ContactUs = () => {
  const [emailSucces, setEmailSucces] = useState<boolean>(true);
  const [showEmailBox, setShowEmailBox] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [showNameError, setShowNameError] = useState<boolean>(false);
  const [showEmailError, setShowEmailError] = useState<boolean>(false);
  const [showMessageError, setShowMessageError] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(true);
  const [newsChecked, setNewsChecked] = useState<boolean>(false);
  const [pressed, setPressed] = useState<boolean>(false);

  const { t } = useTranslation();

  const validateEmail = (email: string) => {
    return email.match(/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/g);
  };

  const resetFields = (success: boolean) => {
    setName('');
    setEmail('');
    setMessage('');
    setEmailSucces(success);
    setShowEmailBox(true);
    setError(true);
    setPressed(false);
  };
  const validateFields = () => {
    if (name === '') {
      setShowNameError(true);
    } else setShowNameError(false);
    if (!validateEmail(email) || email === '') {
      setShowEmailError(true);
    } else setShowEmailError(false);
    if (message === '') {
      setShowMessageError(true);
    } else setShowMessageError(false);
  };
  useEffect(() => {
    if (pressed) {
      if (showNameError || showEmailError || showMessageError) setError(true);
      if (!showNameError && !showEmailError && !showMessageError) setError(false);
    }
  }, [showNameError, showEmailError, showMessageError, pressed]);

  const validateAndSend = async () => {
    if (name && email && message && !error) {
      if (process.env.REACT_APP_ENV === 'test') {
        const emailResponse = await sendEmailToOasisTest(name, email, message);
        if (emailResponse === true) {
          resetFields(true);
          if (newsChecked) saveEmail(name, email);
        } else {
          resetFields(false);
        }
      } else {
        const emailResponse = await sendEmailToOasis(name, email, message);
        if (emailResponse === true) {
          resetFields(true);
          if (newsChecked) saveEmail(name, email);
        } else {
          resetFields(false);
        }
      }
    }
  };

  useEffect(() => {
    validateAndSend();
  }, [error]);
  return (
    <>
      <TheSeparator anchorID="contactus" />
      <div className={styles.headContainer}>
        <HeaderMessage headTitle={t('contactUs.headerTitle')} headDescription={t('contactUs.smallDescription')} />
        {showEmailBox ? (
          <div className={styles.emailResponseBoxBackground}>
            <div className={styles.emailResponseBox}>
              <img src={images.contactUs.emailBoxIcon} />
              <div>
                <div className={styles.emailBoxText}>
                  {emailSucces ? t('contactUs.email.emailSucces') : t('contactUs.email.emailFail')}
                </div>
                <div className={styles.emailBoxText}>
                  {emailSucces ? t('contactUs.email.emailSuccesResp') : t('contactUs.email.emailFailResp')}
                </div>
              </div>
              <button className={styles.newsButton} onClick={() => setShowEmailBox(false)}>
                <span className={styles.emailBoxClose}>{t('contactUs.email.close')}</span>
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}

        <div className={styles.innerContainer}>
          <div className={styles.forceExtend}>
            <div className={styles.newsletterContainerLarge}>
              <div className={styles.containerContactLarge}>
                <div className={styles.spacerInfo}>
                  <img
                    className={styles.iconContact}
                    src={images.contactUs.callerIcon}
                    onClick={() => (window.location = `tel:${phoneNumber}`)}
                  />
                  <span className={styles.contactText}>{t('contactUs.content.telephone')}</span>
                </div>
                <div className={styles.spacerInfo}>
                  <img
                    className={styles.iconContact}
                    src={images.contactUs.emailIcon}
                    onClick={() => (window.location = `mailto:${oasisEmail}`)}
                  />
                  <span className={styles.contactText}>{t('contactUs.content.email')}</span>
                </div>
                <div className={styles.spacerInfo}>
                  <img
                    className={styles.iconContact}
                    src={images.contactUs.mapIcon}
                    onClick={() => window.open(t('contactUs.mapsUrl'), '_blank', 'noopener,noreferrer')}
                  />
                  <span className={styles.contactText}>{t('contactUs.content.address')}</span>
                </div>
              </div>
              <h3 className={styles.newsText}>{t('contactUs.content.newsletterBox.name')}</h3>
              <div className={styles.containerPadder}>
                <input
                  placeholder={t('contactUs.content.newsletterBox.placeholderName')}
                  type={showNameError ? 'textError' : 'text'}
                  value={name}
                  onChange={(text) => setName(text.target.value)}
                  className={styles.inputText}
                />
                <input
                  placeholder={t('contactUs.content.newsletterBox.placeholderEmail')}
                  type={showEmailError ? 'textError' : 'text'}
                  value={email}
                  onChange={(text) => setEmail(text.target.value)}
                  className={styles.inputText}
                />
                <span className={styles.fieldsText}>{t('contactUs.content.newsletterBox.otherDetails')}</span>
                <textarea
                  rows={5}
                  type={showMessageError ? 'detailsContainerError' : 'detailsContainer'}
                  value={message}
                  onChange={(text) => setMessage(text.target.value)}
                  className={styles.inputText}
                />
              </div>
              <div className={styles.newsAction}>
                <label className={`${styles.control}  ${styles.controlcheckbox} `}>
                  <span className={styles.checkBoxText}> {t('contactUs.content.newsletterBox.radioButton')} </span>
                  <input type="checkbox" className={styles.checked} onClick={() => setNewsChecked((prevState) => !prevState)} />
                  <div className={styles.control_indicator}></div>
                </label>

                <button className={styles.newsButton} onClick={() => (validateFields(), setPressed(true))}>
                  {t('contactUs.content.newsletterBox.buttonSend')}
                </button>
              </div>
            </div>
          </div>
          <div className={styles.forceExtenderReversed}>
            <div className={styles.newsletterContainer}>
              <div className={styles.containerPadder}>
                <div>
                  <span className={styles.newsText}>{t('contactUs.content.newsletterBox.name')}</span>
                </div>

                <div >
                  <input
                    placeholder={t('contactUs.content.newsletterBox.placeholderName')}
                    type={showNameError ? 'textError' : 'text'}
                    value={name}
                    onChange={(text) => setName(text.target.value)}
                  />
                  <input
                    placeholder={t('contactUs.content.newsletterBox.placeholderEmail')}
                    type={showEmailError ? 'textError' : 'text'}
                    value={email}
                    onChange={(text) => setEmail(text.target.value)}
                  />

                  <div>
                    <span className={styles.fieldsText}>{t('contactUs.content.newsletterBox.otherDetails')}</span>
                    <textarea
                      type={showMessageError ? 'detailsContainerError' : 'detailsContainer'}
                      value={message}
                      onChange={(text) => setMessage(text.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.newsAction}>
                <label className={`${styles.control}  ${styles.controlcheckbox} `}>
                  <span className={styles.checkBoxText}> {t('contactUs.content.newsletterBox.radioButton')} </span>
                  <input type="checkbox" className={styles.checked} onClick={() => setNewsChecked((prevState) => !prevState)} />
                  <div className={styles.control_indicator}></div>
                </label>
                <button className={styles.newsButton} onClick={() => (validateFields(), setPressed(true))}>
                  {t('contactUs.content.newsletterBox.buttonSend')}
                </button>
              </div>
            </div>
            <div className={styles.mapContainer}>
              <img
                className={styles.mapImage}
                src={images.contactUs.mapLocation}
                onClick={() => window.open(t('contactUs.mapsUrl'), '_blank', 'noopener,noreferrer')}
              />
            </div>
            <div className={styles.contactInfoSmall}>
              <div className={styles.spacerInfo}>
                <img
                  className={styles.iconContact}
                  src={images.contactUs.callerIcon}
                  onClick={() => (window.location = `tel:${phoneNumber}`)}
                />
                <span className={styles.contactText}>{t('contactUs.content.telephone')}</span>
              </div>
              <div className={styles.spacerInfo}>
                <img
                  className={styles.iconContact}
                  src={images.contactUs.emailIcon}
                  onClick={() => (window.location = `mailto:${oasisEmail}`)}
                />
                <span className={styles.contactText}>{t('contactUs.content.email')}</span>
              </div>
              <div className={styles.spacerInfo}>
                <img
                  className={styles.iconContact}
                  src={images.contactUs.mapIcon}
                  onClick={() => window.open(t('contactUs.mapsUrl'), '_blank', 'noopener,noreferrer')}
                />
                <span className={styles.contactText}>{t('contactUs.content.address')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
