const images = {
  logo: require('./../media/assets/oasis_header.svg').default,
  checkInComponent: {
    arrowUp: require('./../media/assets/arrow_up.svg').default,
    arrowDown: require('./../media/assets/arrow_down.svg').default,
  },
  paymentComponent: {
    smiley: require('./../media/assets/smiley.svg').default,
  },
  welcome: {
    mediaItem: require('./../media/assets/image1.png').default,
    playButton: require('./../media/assets/playButton.svg').default,
    pauseButton: require('./../media/assets/pauseButton.svg').default,
    fullScreenButton: require('./../media/assets/fullscreenButton.svg').default
  },
  gallery: {
    rounder: require('./../media/roundButton.svg').default,
    arrowRight: require('./../media/assets/galleryArrowRight.svg').default,
    arrowLeft: require('./../media/assets/galleryArrowLeft.svg').default,
  },
  offers: {
    zigzag: require('./../media/assets/zigzag.svg').default,
    airbnb: require('./../media/assets/buttons/airbnb.png').default,
    bookingcom: require('./../media/assets/buttons/bookingcom.png').default,
    leftCardBoard: require('./../media/assets/pics/offers_cardboard/leftCardBoard.jpg').default,
    rightCardBoard: require('./../media/assets/pics/offers_cardboard/rightCardBoard.jpg').default,
    horizontalZigger: require('./../media/assets/horizontalZigger.png').default,
    checkOutOffer: require('../media/assets/checkOutOffer.png').default,
    prices: require('../media/assets/Prices.png').default,
  },
  review: {
    quiteIcon: require('./../media/assets/pics/reviews/quote_sign.png').default,
    roundButton: require('./../media/roundButton.svg').default,
    roundButtonInactive: require('./../media/roundButton_inactive.svg').default,
    personreview: require('./../media/assets/pics/reviews/personreview.jpg').default,
  },
  contactUs: {
    mapIcon: require('./../media/assets/map_icon.svg').default,
    callerIcon: require('./../media/assets/phone_icon.svg').default,
    emailIcon: require('./../media/assets/email_icon.svg').default,
    mapLocation: require('./../media/assets/pics/map/oasisMap.png').default,
    emailBoxIcon: require('./../media/assets/emailIcon.svg').default,
  },
  footer: {
    logoFooter: require('./../media/assets/oasisFooter.svg').default,
    instagramSocial: require('./../media/assets/socialsIcon/instagram_social.svg').default,
    facebookSocial: require('./../media/assets/socialsIcon/facebook_social.svg').default,
    airbnbSocial: require('./../media/assets/socialsIcon/airbnb_social.svg').default,
    bookingSocial: require('./../media/assets/socialsIcon/bookingFooter.svg').default,
    nmcpLogo: require('./../media/assets/nmcp.png').default,
  },
  scrollUp: {
    upButtonCircle: require('./../media/assets/upButtonCircle.svg').default,
    upButtonArrow: require('./../media/assets/upButtonArrow.svg').default,
  },

  booking: {
    closeButton: require('./../media/assets/bookingClose.svg').default,
    tooltip: require('./../media/tooltip.svg').default
  },

  oasisResidenceImage: {
    room1: require('./../media/assets/pics/residencePics/CRS_7095-HDR-min.jpg').default,
    room2: require('./../media/assets/pics/residencePics/CRS_7098-HDR-min.jpg').default,
    room3: require('./../media/assets/pics/residencePics/CRS_7101-HDR-min.jpg').default,
    room4: require('./../media/assets/pics/residencePics/CRS_7104-HDR-min.jpg').default,
    room5: require('./../media/assets/pics/residencePics/CRS_7107-HDR-min.jpg').default,
    room6: require('./../media/assets/pics/residencePics/CRS_7110-HDR-min.jpg').default,
    room7: require('./../media/assets/pics/residencePics/CRS_7113-HDR-min.jpg').default,
    room8: require('./../media/assets/pics/residencePics/CRS_7118-HDR-min.jpg').default,
    room9: require('./../media/assets/pics/residencePics/CRS_7121-HDR-min.jpg').default,
    room10: require('./../media/assets/pics/residencePics/CRS_7128-HDR-min.jpg').default,
    room11: require('./../media/assets/pics/residencePics/CRS_7131-HDR-min.jpg').default,
    room12: require('./../media/assets/pics/residencePics/CRS_7131-HDR-min.jpg').default,
  },
  facilitiesComponent: {
    air: require('./../media/assets/facilities/air.svg').default,
    park: require('./../media/assets/facilities/park.svg').default,
    self: require('./../media/assets/facilities/self.svg').default,
    wash: require('./../media/assets/facilities/wash.svg').default,
    hygiene: require('./../media/assets/facilities/hygiene.svg').default,
    heat: require('./../media/assets/facilities/heat.svg').default,
    security: require('./../media/assets/facilities/security.svg').default,
    netflix: require('./../media/assets/facilities/netflix.svg').default,
    balcony: require('./../media/assets/facilities/balcony.svg').default,
  },
  photoGalleryComponent: {
    photo1: require('./../media/assets/photoGallery/photo1.jpeg').default,
    photo2: require('./../media/assets/photoGallery/photo2.jpeg').default,
    photo3: require('./../media/assets/photoGallery/photo3.jpeg').default,
    photo4: require('./../media/assets/photoGallery/photo4.jpeg').default,
    photo5: require('./../media/assets/photoGallery/photo5.jpeg').default,
    photo6: require('./../media/assets/photoGallery/photo6.jpeg').default,
    photo7: require('./../media/assets/photoGallery/photo7.jpeg').default,
    photo8: require('./../media/assets/photoGallery/photo8.jpeg').default,
    photo9: require('./../media/assets/photoGallery/photo9.jpeg').default,
    photo10: require('./../media/assets/photoGallery/photo10.jpeg').default,
    photo11: require('./../media/assets/photoGallery/photo11.jpeg').default,
    photo12: require('./../media/assets/photoGallery/photo12.jpeg').default,
    photo13: require('./../media/assets/photoGallery/photo13.jpeg').default,
    photo14: require('./../media/assets/photoGallery/photo14.jpeg').default,
    photo15: require('./../media/assets/photoGallery/photo15.jpeg').default,
    photo16: require('./../media/assets/photoGallery/photo16.jpeg').default,
    photo17: require('./../media/assets/photoGallery/photo17.jpeg').default,
  },
  reviewsComponent: {
    mihai: require('./../media/assets/mihai.png').default,
    casandra: require('./../media/assets/casandra.png').default,
    madalina_victoria: require('./../media/assets/madalina_victoria.png').default,
    tomasz: require('./../media/assets/tomasz.png').default,
    victor: require('./../media/assets/victor.png').default,
  },
  newsletter: {
    closedEmail: require('./../media/assets/closedEmail.svg').default
  }
};

export default images;
