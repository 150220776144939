import React, { useEffect, useRef, useState } from 'react';
import NavBarResidence from './NavBarResidence';
import CheckinComponent from './CheckinComponent';

import styles from './ResidenceLanding.module.scss';
import images from '../data/images';
import oasisVideo from '../media/assets/oasisVideo.mp4';
import PopUpComponent from './PopUpComponent';
import ThankYouNewsletter from './ThankYouNewsletter';

interface Props {
  showThankYou: boolean;
  setShowThankYou: (item:boolean) => void;
}

const ResidenceLanding: React.FC<Props> = ({setShowThankYou, showThankYou }) => {
  const vidRef = useRef(null);
  const [videoState, setVideoState] = useState<string>('playing');
  const [showButton, setShowButton] = useState<boolean>(false);
  const [volume, setVolume] = useState<string>('0');
  const [videoWidth, setVideoWidth] = useState<number>(0);
  const [showPopUp, setShowPopUp] = useState<boolean>(false);

  const handlePlayVideo = () => {
    if (videoState === 'paused') {
      vidRef.current.play();

      setVideoState('playing');
      setTimeout(() => {
        setShowButton(false);
      }, 3000);
    } else {
      vidRef.current.pause();
      setVideoState('paused');
    }
  };

  const goFullScreen = () => {
    vidRef.current.requestFullscreen();
  };

  const changeVolume = () => {
    vidRef.current.volume = parseInt(volume) / 100;
  };

  const hidePlayButton = () => {
    return videoState !== 'paused' ? setShowButton(false) : null;
  };

  const showPlayButton = () => {
    setShowButton(true);
  };
  useEffect(() => {
    setVideoWidth(vidRef.current.videoWidth);
    setTimeout(() => {
      setShowPopUp(true);
    }, 7500);
  }, []);

  useEffect(() => {
    changeVolume();
  }, [volume]);

  function toggleFullscreen() {
    if (vidRef.current.requestFullScreen) {
      vidRef.current.requestFullScreen();
    } else if (vidRef.current.webkitRequestFullScreen) {
      vidRef.current.webkitRequestFullScreen();
    } else if (vidRef.current.mozRequestFullScreen) {
      vidRef.current.mozRequestFullScreen();
    }
  }

  vidRef.current?.addEventListener('dblclick', toggleFullscreen);

  return (
    <div id={'home'} className={styles.heroSection}>
      <NavBarResidence />
      <CheckinComponent />
      {showPopUp ? (
        <PopUpComponent
          showPopUp={showPopUp}
          setShowPopUp={setShowPopUp}
          showThankYou={showThankYou}
          setShowThankYou={setShowThankYou}
        />
      ) : (
        <div></div>
      )}
      {showThankYou ? <ThankYouNewsletter setShowThankYou={setShowThankYou} /> : <div></div>}
      <div className={styles.videoPlayer}>
        <video
          muted
          playsInline
          onMouseLeave={hidePlayButton}
          onMouseEnter={showPlayButton}
          ref={vidRef}
          id="video1"
          className={styles.oasisVideo}
          autoPlay
          onPause={() => {
            setVideoState('paused');
            setShowButton(true);
          }}
          onPlaying={() => {
            setVideoState('playing');
          }}
          onEnded={() => {
            setVideoState('paused');
            setShowButton(true);
          }}
          onLoadStart={() => (vidRef.current.volume = 0)}
          onClick={() => {
            handlePlayVideo();
          }}
        >
          <source src={oasisVideo} type="video/mp4" />
        </video>
        {/* <div className={styles.videoButtons} style={{}}>
          <input
            className={styles.volumeSlider}
            type="range"
            min="0"
            max="100"
            step="1"
            value={volume}
            onChange={(e) => setVolume(e.target.value)}
          />
          <img
            onMouseLeave={hidePlayButton}
            onMouseEnter={showPlayButton}
            onClick={goFullScreen}
            src={images.welcome.fullScreenButton}
            className={styles.fullScreenButton}
          />
        </div> */}
      </div>

      {showButton ? (
        <img
          onMouseLeave={hidePlayButton}
          onMouseEnter={showPlayButton}
          className={styles.playButton}
          src={videoState === 'paused' ? images.welcome.playButton : images.welcome.pauseButton}
          onClick={() => {
            handlePlayVideo();
          }}
        />
      ) : null}
    </div>
  );
};

export default ResidenceLanding;
