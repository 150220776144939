import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import images from '../data/images';
import styles from './../components/PersonalDetailsComponent.module.scss';

import strings from './../data/strings.json';
import { calculateAmount, reserveTempDates } from '../functions/firebase';
import { createSessionTest, createSession } from '../services/payments';
import ReactPixel from 'react-facebook-pixel';

interface IProps {
  setShowBooking: (showBooking: boolean) => void;
  setShowPersonal: (showPersonal: boolean) => void;
  setShowPayment: (showPayment: boolean) => void;
  setOtherDeatils: (otherDetails: string) => void;
  persons: number;
  name: string;
  email: string;
  companyName: string;
  registrationNumber: string;
  fiscalCode: string;
  city: string;
  county: string;
  otherDetails: string;
  checkOutString: string;
  checkInString: string;
  countryPre: string;
  address: string;
  type: string;
  resetStates: () => void;
}

const PersonalDetails = ({
  setShowPersonal,
  setShowBooking,
  name,
  email,
  otherDetails,
  checkOutString,
  checkInString,
  persons,
  reservationObject,
  setOtherDetails,
  type,
  countryPre,
  address,
  city,
  county,
  adress,
  companyName,
  registrationNumber,
  fiscalCode,
}: any) => {
  const { t } = useTranslation();

  const [price, setPrice] = useState<number>(0);
  const [numberOfDays, setNumberOfDays] = useState<number>(0);
  const [sessionUrl, setSessionUrl] = useState<string>('');
  const [reservationDates, setReservationDates] = useState<string>('');
  const calculateBookingDays = async () => {
    const startDate =
      reservationObject.checkInDates.from?.year +
      '/' +
      reservationObject.checkInDates.from?.month +
      '/' +
      reservationObject.checkInDates.from?.day;
    const endDate =
      reservationObject.checkInDates.to?.year +
      '/' +
      reservationObject.checkInDates.to?.month +
      '/' +
      reservationObject.checkInDates.to?.day;
    setReservationDates(startDate + ' - ' + endDate);
    const diffInMs = new Date(endDate).valueOf() - new Date(startDate).valueOf();
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    let discountDays = diffInDays;
    for (let i = 1; i <= diffInDays; i++) {
      if (i % 4 === 0) {
        discountDays = discountDays - 1;
      }
    }
    setNumberOfDays(discountDays);
    const tempPrice: number = await calculateAmount(diffInDays);
    setPrice(tempPrice);
    return tempPrice;
  };

  const completeRegistrationConv = () => {
    ReactPixel.fbq('track', 'CompleteRegistration');
  };

  const reservationDate: string =
    checkInString + '-' + checkOutString + ',' + ' ' + persons + ' ' + t('checkinComponent.personsNumber');

  const createSessionUrl = async () => {
    const docId = await reserveTempDates(
      name,
      email,
      reservationObject.details,
      reservationObject.checkInDates,
      reservationDates,
      numberOfDays,
      address,
      countryPre,
      fiscalCode || '-',
      registrationNumber || '-',
      city,
      county,
      companyName || '-',
      type,
    );
    const url =
      process.env.REACT_APP_ENV === 'test'
        ? await createSessionTest(numberOfDays, email, reservationDate, docId)
        : await createSession(numberOfDays, email, reservationDate, docId);
    setSessionUrl(url);
  };

  useEffect(() => {
    calculateBookingDays();
  }, []);

  useEffect(() => {
    if (numberOfDays) createSessionUrl();
  }, [numberOfDays]);

  return (
    <div className={styles.container}>
      <img
        className={styles.closeImage}
        src={images.booking.closeButton}
        onClick={() => {
          setShowPersonal(false);
          setOtherDetails('');
        }}
      />
      <span className={styles.title}>{t('personalComponent.personalDetails')}</span>
      {type === 'individual' ? (
        <div className={styles.detailsContainer}>
          <div className={styles.details}>
            {t('invoicePage.placeholders.name')}: {name}
          </div>
          <div className={styles.details}>
            {t('invoicePage.placeholders.email')}: {email}
          </div>
          <div className={styles.details}>
            {t('invoicePage.placeholders.town')}: {city}
          </div>
          <div className={styles.details}>
            {t('invoicePage.placeholders.region')}: {county}
          </div>
          <div className={styles.details}>
            {t('invoicePage.placeholders.fullAddress')}: {adress}
          </div>
          <div className={styles.details}>
            {t('personalComponent.otherDetails')}
            {otherDetails}
          </div>
          <div className={styles.details}>
            {checkInString + '-' + checkOutString + ',' + ' ' + persons + ' ' + t('checkinComponent.personsNumber')}
          </div>
          <div className={styles.details}>
            {t('invoicePage.price')}: {price} ron
          </div>
        </div>
      ) : (
        <div className={styles.detailsContainer}>
          <div className={styles.details}>
            {t('invoicePage.placeholders.name')}: {name}
          </div>
          <div className={styles.details}>
            {t('invoicePage.placeholders.email')}: {email}
          </div>
          <div className={styles.details}>
            {t('invoicePage.placeholders.companyName')}: {companyName}
          </div>
          <div className={styles.details}>
            {t('invoicePage.placeholders.town')}: {city}
          </div>
          <div className={styles.details}>
            {t('invoicePage.placeholders.region')}: {county}
          </div>
          <div className={styles.details}>
            {t('invoicePage.placeholders.fullAddress')}: {adress}
          </div>
          <div className={styles.details}>
            {t('invoicePage.placeholders.registrationNumber')}: {registrationNumber}
          </div>
          <div className={styles.details}>
            {t('invoicePage.placeholders.fiscalCode')}: {fiscalCode}
          </div>
          <div className={styles.details}>
            {checkInString + '-' + checkOutString + ',' + ' ' + persons + ' ' + t('checkinComponent.personsNumber')}
          </div>
          <div className={styles.details}>
            {t('invoicePage.price')}: {price} ron
          </div>
        </div>
      )}
      <div className={styles.buttonsContainer}>
        <button
          className={styles.backButton}
          onClick={() => {
            setShowPersonal(false);
            setShowBooking(true);
            setOtherDetails('');
          }}
        >
          <span className={styles.buttonsText}>{t('personalComponent.back')}</span>
        </button>
        <button
          className={styles.payButton}
          onClick={() => {
            if (sessionUrl) window.location = sessionUrl;
            completeRegistrationConv();
          }}
        >
          <span className={styles.buttonsText}>{t('personalComponent.pay')}</span>
        </button>
      </div>
    </div>
  );
};

export default PersonalDetails;
