import React from 'react';
import { HashLink as Link, NavHashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

import ReactGA from 'react-ga4';

import images from './../data/images';

import styles from './NavBarResidence.module.scss';


import MenuMobile from './../components/MenuMobile';

const NavBarResidence = () => {
  const { t } = useTranslation();

  const sendAnalyticsForGallery = () => {
    ReactGA.event('User pressed on gallery');
  };
  const sendAnalyticsForAboutUs = () => {
    ReactGA.event('User pressed on AboutUs');
  };
  const sendAnalyticsForOffers = () => {
    ReactGA.event('User pressed on Offers');
  };
  const sendAnalyticsForReviews = () => {
    ReactGA.event('User pressed on Reviews');
  };
  const sendAnalyticsForLocation = () => {
    ReactGA.event('User pressed on Location');
  };
  const sendAnalyticsForContactUs = () => {
    ReactGA.event('User pressed on ContactUs');
  };

  return (
    <div className={styles.navbarContainer}>
      <ul className={styles.ulMenuEnd}>
      <li onClick={() => sendAnalyticsForAboutUs()} className={styles.liItem}>
          <NavHashLink className={styles.HashLinkStyle} to="#aboutus">
            {t('navMenu.aboutUs')}
          </NavHashLink>
        </li>
        <li onClick={() => sendAnalyticsForGallery()} className={styles.liItem}>
          <NavHashLink className={styles.HashLinkStyle} smooth to="#gallery">
            {t('navMenu.gallery')}
          </NavHashLink>
        </li>
       
        <li onClick={() => sendAnalyticsForOffers()} className={styles.liItem}>
          <NavHashLink className={styles.HashLinkStyle} to="#offers">
            {t('navMenu.offers')}
          </NavHashLink>
        </li>
      </ul>
      <div className={styles.middleNoUl}>
        <NavHashLink to="#">
          <img className={styles.navLogo} src={images.logo} />
        </NavHashLink>
      </div>

      <MenuMobile />
      <ul className={styles.ulMenuStart}>
        <li onClick={() => sendAnalyticsForReviews()} className={styles.liItem}>
          <NavHashLink className={styles.HashLinkStyle} to="#reviews">
            {t('navMenu.reviews')}
          </NavHashLink>
        </li>
        <li onClick={() => sendAnalyticsForLocation()} className={styles.liItem}>
          <NavHashLink className={styles.HashLinkStyle} to="#contactus">
            {t('navMenu.location')}
          </NavHashLink>
        </li>
        <li onClick={() => sendAnalyticsForContactUs()} className={styles.liItem}>
          <NavHashLink className={styles.HashLinkStyle} to="#contactus">
            {t('navMenu.contactUs')}
          </NavHashLink>
        </li>
      </ul>
    </div>
  );
};

export default NavBarResidence;
