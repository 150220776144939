export const sendReservationMail = async (
  name: string,
  email1: string,
  email2: string,
  phone: string,
  details: string,
  dates: string,
  persons: number,
) => {
  return await fetch('https://us-central1-oasisresidenceweb-b7f37.cloudfunctions.net/sendEmail', {
    method: 'POST',
    mode: 'no-cors',
    body: JSON.stringify({ email1: email1, email2: email2, name: name, phone: phone, details: details, dates: dates, persons: persons }),
  })
    .then((res) => res)
    .catch((error) => error);
};

export const sendEmailToOasis = async (name: string, email: string, message: string) => {
  return await fetch('https://us-central1-oasisresidenceweb-b7f37.cloudfunctions.net/sendEmailToOasis', {
    method: 'POST',
    mode: 'no-cors',
    body: JSON.stringify({ name: name, email: email, message: message }),
  })
    .then(() => true)
    .catch(() => false);
};

export const sendEmailToOasisTest = async (name: string, email: string, message: string) => {
  return await fetch('https://us-central1-oasisresidencewebdev.cloudfunctions.net/sendEmailToOasisTest', {
    method: 'POST',
    mode: 'no-cors',
    body: JSON.stringify({ name: name, email: email, message: message }),
  })
    .then(() => true)
    .catch(() => false);
};

export const saveEmail = async (name: string, email:string) => {
  return await fetch('https://us-central1-oasisresidencewebdev.cloudfunctions.net/sendEmailToOasisTest',{
    method:'POST',
    mode:'no-cors',
    body: JSON.stringify({name:name,email:email})
  })
    .then((response) => response)
    .catch((err) => err)
}

export const sendEmailForNewsletter = async (email:string) => {
  return await fetch('https://us-central1-oasisresidencewebdev.cloudfunctions.net/sendEmailForNewsletter',{
    method:'POST',
    mode:'no-cors',
    body: JSON.stringify({email:email})
  })
    .then((response) => response)
    .catch((err) => err)
}