import images from './../data/images';

import styles from './ReviewCard.module.scss';

interface ReviewerInterface {
  authorName: string;
  testimonialText: string;
  firstLetter: string;
}
interface IProps {
  styler?: string;
  profilePicture?:any;
  testimonials?: ReviewerInterface;
}

const ReviewCard = ({ styler, testimonials, profilePicture }: IProps) => {
  return (
    <div
      className={` ${styles.cardReview}   ${styler === 'onFocus' ? styles.onFocus : styler === 'hide' ? styles.hideThis : ''}  `}
    >
      <div className={styles.padder}>
        <div className={styles.innerContainerStart}>
          <div className={styles.quoteSignLeft}>"</div>
        </div>
        <div className={styles.outerCard}>
          <span className={styles.cardText}>{testimonials?.testimonialText}</span>
        </div>
        <div className={styles.innerContainerEnd}>
          <div className={styles.quoteSignRight}>"</div>
        </div>
      </div>
      <div className={styles.authorContainer}>
        <div className={styles.profilePicture}>
          <div className={styles.actualImage}>{testimonials.firstLetter}</div>
        </div>
        <span className={styles.reviewAuthor}>{testimonials?.authorName}</span>
      </div>
    </div>
  );
};
export default ReviewCard;
