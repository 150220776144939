import { useTranslation } from 'react-i18next';

import images from '../data/images';
import styles from './../components/PaymentSuccessful.module.scss';


interface IProps {
  setShowPayment: (showPayment: boolean) => void;
  sendAnalyticsForClosePaymentConfirmation: () => void;
}

const PaymentSuccessful: React.FC<IProps> = ({setShowPayment,sendAnalyticsForClosePaymentConfirmation}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <img src={images.paymentComponent.smiley} className={styles.smiley}/>
      <div className={styles.title}>{t('paymentComponent.title')}</div>
      <div>
        <div className={styles.details}>{t('paymentComponent.thankYou')}</div>
        <div className={styles.details}>{t('paymentComponent.invoice')}</div>
        <div className={styles.details}>{t('paymentComponent.please')}</div>
        <div className={styles.details}>{t('paymentComponent.contactUs')}</div>
      </div>
      <button
        className={styles.okButton}
        onClick={() => {
          sendAnalyticsForClosePaymentConfirmation();
          setShowPayment(false);
        }}
      >
        <span className={styles.buttonsText}>{t('paymentComponent.ok')}</span>
      </button>
    </div>
  );
};

export default PaymentSuccessful;
