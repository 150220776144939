import { useTranslation } from 'react-i18next';

import styles from './../blocks/FacilitiesBlock.module.scss';

import images from '../data/images';

const Facilities = () => {

  const { t } = useTranslation();

  const facilities = [
    {
      image: images.facilitiesComponent.air,
      text: t('facilitiesComponent.air'),
    },
    {
      image: images.facilitiesComponent.park,
      text: t('facilitiesComponent.park'),
    },
    {
      image: images.facilitiesComponent.self,
      text: t('facilitiesComponent.self'),
    },
    {
      image: images.facilitiesComponent.wash,
      text: t('facilitiesComponent.wash'),
    },
    {
      image: images.facilitiesComponent.hygiene,
      text: t('facilitiesComponent.hygiene'),
    },
    {
      image: images.facilitiesComponent.balcony,
      text: t('facilitiesComponent.heat'),
    },
    {
      image: images.facilitiesComponent.heat,
      text: t('facilitiesComponent.security'),
    },
    {
      image: images.facilitiesComponent.security,
      text: t('facilitiesComponent.netflix'),
    },
    {
      image: images.facilitiesComponent.netflix,
      text: t('facilitiesComponent.balcony'),
    },
  ];

  return (
    <div className={styles.container}>
      {facilities.map((facility) => (
        <div className={styles.blockContainer}>
          <img className={styles.image} src={facility.image} />
          <div className={styles.subText}>{facility.text}</div>
        </div>
      ))}
    </div>
  );
};

export default Facilities;
