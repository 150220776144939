import images from '../data/images';
import strings from '../data/strings.json';

export const facilities = [
  {
    image: images.facilitiesComponent.air,
    text: strings.facilitiesComponent.air,
  },
  {
    image: images.facilitiesComponent.park,
    text: strings.facilitiesComponent.park,
  },
  {
    image: images.facilitiesComponent.self,
    text: strings.facilitiesComponent.self,
  },
  {
    image: images.facilitiesComponent.wash,
    text: strings.facilitiesComponent.wash,
  },
  {
    image: images.facilitiesComponent.hygiene,
    text: strings.facilitiesComponent.hygiene,
  },
  {
    image: images.facilitiesComponent.balcony,
    text: strings.facilitiesComponent.heat,
  },
  {
    image: images.facilitiesComponent.heat,
    text: strings.facilitiesComponent.security,
  },
  {
    image: images.facilitiesComponent.security,
    text: strings.facilitiesComponent.netflix,
  },
  {
    image: images.facilitiesComponent.netflix,
    text: strings.facilitiesComponent.balcony,
  },
];

export const photoGallery = [
  images.photoGalleryComponent.photo1,
  images.photoGalleryComponent.photo2,
  images.photoGalleryComponent.photo3,
  images.photoGalleryComponent.photo4,
  images.photoGalleryComponent.photo5,
  images.photoGalleryComponent.photo6,
  images.photoGalleryComponent.photo7,
  images.photoGalleryComponent.photo8,
  images.photoGalleryComponent.photo9,
  images.photoGalleryComponent.photo10,
  images.photoGalleryComponent.photo11,
  images.photoGalleryComponent.photo12,
  images.photoGalleryComponent.photo13,
  images.photoGalleryComponent.photo14,
  images.photoGalleryComponent.photo15,
  images.photoGalleryComponent.photo16,
  images.photoGalleryComponent.photo17,
];

export const personsList = [
  strings.personList.onePerson,
  strings.personList.twoPersons,
  strings.personList.threePersons,
  strings.personList.fourPersons,
];
