import { useTranslation } from 'react-i18next';

import TheSeparator from '../components/TheSeparator';

import styles from './AboutUs.module.scss';


const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <>
      <TheSeparator anchorID="aboutus" />
      <div id={'aboutus'} className={styles.blockContainer}>
        <div className={styles.innerContainer}>
          <span className={styles.frontWelcome}>{t('aboutUsSection.smallDescription')}</span>
          <h2 className={styles.welcomeHead}>{t('aboutUsSection.headerTitle')}</h2>
          <div className={styles.exteriorGalleryContain}>
            <div className={styles.galleryContainer}>
              <div className={styles.contentImage}></div>

              <span className={styles.fullDescription}>{t('aboutUsSection.aboutUsDescription')}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
