import React from 'react';
import { useTranslation } from 'react-i18next';

import ItemDescribe from '../components/ItemDescribe';
import TheSeparator from '../components/TheSeparator';
import HeaderMessage from '../components/HeaderMessage';

import styles from './../blocks/WelcomeBlock.module.scss';

import images from './../data/images';

interface paramsIn {
  title: string;
  longText: string;
}
interface paramsIn extends Array<paramsIn> {}

const WelcomeBlock = () => {
  const { t } = useTranslation();

  const descriptionList = {
    style: {
      title: t('welcomeSection.descriptionList.0.title'),
      longText: t('welcomeSection.descriptionList.0.longText'),
    },
    warm: {
      title: t('welcomeSection.descriptionList.1.title'),
      longText: t('welcomeSection.descriptionList.1.longText'),
    },
    cozy: {
      title: t('welcomeSection.descriptionList.2.title'),
      longText: t('welcomeSection.descriptionList.2.longText'),
    },
    central: {
      title: t('welcomeSection.descriptionList.3.title'),
      longText: t('welcomeSection.descriptionList.3.longText'),
    },
    premium: {
      title: t('welcomeSection.descriptionList.4.title'),
      longText: t('welcomeSection.descriptionList.4.longText'),
    },
    elegance: {
      title: t('welcomeSection.descriptionList.5.title'),
      longText: t('welcomeSection.descriptionList.5.longText'),
    },
  };
  return (
    <>
      <TheSeparator />
      <div className={styles.blockContainer}>
        <div className={styles.innerWrap}>
          <HeaderMessage headTitle={t('welcomeSection.headerTitle')} headDescription={t('welcomeSection.smallDescription')} />
          <div className={styles.descript}>
            <div className={styles.descriptionContainer}>
              <ItemDescribe data={descriptionList.style} alignment={'text-end'} />
              <ItemDescribe data={descriptionList.warm} alignment={'text-end'} />
              <ItemDescribe data={descriptionList.cozy} alignment={'text-end'} />
            </div>
            <div className={styles.middleContainer}>
              <img className={styles.middleimage} src={images.welcome.mediaItem} />
              <div className={styles.middleTextMobile}>
                <span className={styles.headTitleMobile}>{descriptionList.style.title}</span>
                <span className={styles.headTitleMobile}>{descriptionList.cozy.title}</span>
                <span className={styles.headTitleMobile}>{descriptionList.premium.title}</span>
                <span className={styles.headTitleMobile}>{descriptionList.warm.title}</span>
                <span className={styles.headTitleMobile}>{descriptionList.central.title}</span>
                <span className={styles.headTitleMobile}>{descriptionList.elegance.title}</span>
              </div>
            </div>
            <div className={styles.descriptionContainer}>
              <ItemDescribe data={descriptionList.central} alignment={'text-start'} />
              <ItemDescribe data={descriptionList.premium} alignment={'text-start'} />
              <ItemDescribe data={descriptionList.elegance} alignment={'text-start'} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeBlock;
