import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Calendar, DayRange, DayValue } from 'react-modern-calendar-datepicker';
import { useTranslation } from 'react-i18next';

import styles from './../components/BookingComponent.module.scss';
import images from './../data/images';
import { verifyDates, getDates } from '../functions/firebase';
import { getDisabledDays, validateEmail } from '../functions/helpers';
import { personsList } from '../data/arrays';
import InvoicePage from './InvoicePage';

interface CustomisedDays {
  year: number;
  month: number;
  day: number;
  className: string;
}

interface IProps {
  setReservationObject: (reservationObject: any) => void;
  setShowBooking: (showBooking: boolean) => void;
  setCheckInDates: (checkInDates: DayRange) => void;
  setCheckOutDate: (checkOutDate: DayValue) => void;
  setPersons: (persons: number) => void;
  setShowPersonal: (ShowPersonal: boolean) => void;
  setName: (name: string) => void;
  setEmail: (email: string) => void;
  setType: (type: string) => void;
  setOtherDetails: (otherDetails: string) => void;
  setCheckOutString: (checkOutString: string) => void;
  setCheckInString: (checkInString: string) => void;
  sendAnalyticsForNext: () => void;
  setNewsChecked: (item: boolean) => void;
  setPersonalData: (item: boolean) => void;
  newsChecked: boolean;
  persons: number;
  name: string;
  email: string;
  personalData: boolean;
  otherDetails: string;
  checkInDates: DayRange;
  checkOutDate: DayValue;
  checkOutString: string;
  checkInString: string;
  type: string;
  countryPre: string;
  checkInDaysCustomised: CustomisedDays[];
  setCountryPre: (countryPre: string) => void;
  setAdress: (adress: string) => void;
  setCompanyName: (companyname: string) => void;
  setRegistrationNumber: (registrationNumber: string) => void;
  setFiscalCode: (fiscalCode: string) => void;
  setCity: (city: string) => void;
  setCounty: (county: string) => void;
  adress: string;
  companyName: string;
  registrationNumber: string;
  fiscalCode: string;
  city: string;
  county: string;
}

interface DisabledDays {
  year: number;
  month: number;
  day: number;
}

const BookingComponent: React.FC<IProps> = ({
  setReservationObject,
  setShowBooking,
  checkInDates,
  checkOutDate,
  setCheckInDates,
  setCheckOutDate,
  persons,
  setPersons,
  setShowPersonal,
  name,
  setName,
  email,
  setEmail,
  personalData,
  setPersonalData,
  otherDetails,
  setOtherDetails,
  checkOutString,
  setCheckOutString,
  checkInString,
  setCheckInString,
  sendAnalyticsForNext,
  setNewsChecked,
  newsChecked,
  checkInDaysCustomised,
  type,
  setType,
  setAdress,
  setCompanyName,
  setRegistrationNumber,
  setFiscalCode,
  setCity,
  setCounty,
  countryPre,
  setCountryPre,
  adress,
  companyName,
  registrationNumber,
  fiscalCode,
  city,
  county,
}) => {
  const [showCheckInCalendar, setShowCheckInCalendar] = useState<boolean>(false);
  const [showCheckOutCalendar, setShowCheckOutCalendar] = useState<boolean>(false);
  const [showPersonsList, setShowPersonsList] = useState<boolean>(false);
  const [showNameError, setShowNameError] = useState<boolean>(false);
  const [showEmailError, setShowEmailError] = useState<boolean>(false);
  const [showTownError, setShowTownError] = useState<boolean>(false);
  const [showRegionError, setShowRegionError] = useState<boolean>(false);
  const [showAdressError, setShowAdressError] = useState<boolean>(false);
  const [showCountryError, setShowCountryError] = useState<boolean>(false);
  const [showFiscalCodeError, setShowFiscalCodeError] = useState<boolean>(false);
  const [showRegNumberError, setShowRegNumberError] = useState<boolean>(false);
  const [showCompanyNameError, setShowCompanyNameError] = useState<boolean>(false);
  const [showCheckInError, setShowCheckInError] = useState<boolean>(false);
  const [showCheckOutError, setShowCheckOutError] = useState<boolean>(false);
  const [personalError, setPersonalError] = useState<boolean>(false);
  const [disabledDays, setDisabledDays] = useState<DisabledDays[]>([]);
  const [numberOfDays, setNumberOfDays] = useState<number>(0);
  const [datesErrorString, setDatesErrorString] = useState('');
  const { t } = useTranslation();

  const currentDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };

  const validateFieldsIndividual = () => {
    if (name === '') {
      setShowNameError(true);
    }
    if (email === '' || !validateEmail(email)) {
      setShowEmailError(true);
    }
    if (city === '') {
      setShowTownError(true);
    }
    if (county === '') {
      setShowRegionError(true);
    }
    if (adress === '') {
      setShowAdressError(true);
    }
    if (countryPre === '') {
      setShowCountryError(true);
    }
    if (checkInString === '') {
      setShowCheckInError(true);
    }
    if (checkOutString.includes('undefined')) {
      setShowCheckOutError(true);
    }
    if (personalData === false) {
      setPersonalError(true);
    }
  };

  const validateFieldsOrganisation = () => {
    if (name === '') {
      setShowNameError(true);
    }
    if (email === '' || !validateEmail(email)) {
      setShowEmailError(true);
    }
    if (city === '') {
      setShowTownError(true);
    }
    if (county === '') {
      setShowRegionError(true);
    }
    if (adress === '') {
      setShowAdressError(true);
    }
    if (countryPre === '') {
      setShowCountryError(true);
    }
    if (fiscalCode === '') {
      setShowFiscalCodeError(true);
    }
    if (registrationNumber === '') {
      setShowRegNumberError(true);
    }
    if (companyName === '') {
      setShowCompanyNameError(true);
    }
    if (checkInString === '') {
      setShowCheckInError(true);
    }
    if (checkOutString.includes('undefined')) {
      setShowCheckOutError(true);
    }
    if (personalData === false) {
      setPersonalError(true);
    }
  };
  useEffect(() => {
    getDisabledDays(setDisabledDays);
  }, []);

  const handleNext = useCallback(async () => {
    if (type === 'individual') validateFieldsIndividual();
    if (type === 'entity') validateFieldsOrganisation();
    if (
      (type === 'individual' &&
        name !== '' &&
        email !== '' &&
        county !== '' &&
        city !== '' &&
        countryPre !== '' &&
        adress !== '' &&
        checkInString !== '' &&
        !checkOutString.includes('undefined') &&
        personalData) ||
      (type !== 'individual' &&
        name !== '' &&
        email !== '' &&
        county !== '' &&
        city !== '' &&
        countryPre !== '' &&
        adress !== '' &&
        registrationNumber !== '' &&
        fiscalCode !== '' &&
        checkInString !== '' &&
        !checkOutString.includes('undefined') &&
        personalData)
    ) {
      const reservationObject = {
        fullname: companyName ? companyName : name,
        email: email,
        details: otherDetails,
        checkInDates: checkInDates,
        datesForEmail: checkInString + ' - ' + checkOutString,
        persons: persons,
      };
      setDatesErrorString('');
      const { nextYearDatesArray, currentYearDatesArray } = await verifyDates();
      const startDate = checkInDates.from?.year + '-' + checkInDates.from?.month + '-' + checkInDates.from?.day;
      const endDate = checkInDates.to?.year + '-' + checkInDates.to?.month + '-' + checkInDates.to?.day;
      const selectedDays = getDates(startDate, endDate);
      let goNext = true;
      selectedDays.forEach((element) => {
        if (nextYearDatesArray.includes(element) || currentYearDatesArray.includes(element)) {
          setDatesErrorString((prev) => prev + ' ' + element);
          goNext = false;
        }
      });
      setReservationObject(reservationObject);
      setShowPersonal(true);
      setShowBooking(false);
      if (otherDetails === '') {
        setOtherDetails('-');
      }
      sendAnalyticsForNext();
      setPersonalData(false);
    }
  }, [
    checkInDates,
    name,
    email,
    city,
    county,
    fiscalCode,
    registrationNumber,
    countryPre,
    companyName,
    adress,
    checkInString,
    checkOutString,
    personalData,
    type,
  ]);

  useEffect(() => {
    setCheckOutDate(checkInDates.to);
    if (checkOutDate != null) {
      const checkOutYear = JSON.stringify(checkInDates.to?.year);
      const checkOutMonth = JSON.stringify(checkInDates.to?.month);
      const checkOutDay = JSON.stringify(checkInDates.to?.day);
      const checkInYear = JSON.stringify(checkInDates?.from?.year);
      const checkInMonth = JSON.stringify(checkInDates?.from?.month);
      const checkInDay = JSON.stringify(checkInDates?.from?.day);
      setCheckOutString(checkOutDay + '/' + checkOutMonth + '/' + checkOutYear);
      setCheckInString(checkInDay + '/' + checkInMonth + '/' + checkInYear);
    }
  }, [checkInDates, checkOutDate]);


  const renderPersonList = () => {
    return personsList.map((item, index) => (
      <div
        className={persons === index + 1 ? styles.personListTextSelected : styles.personListText}
        onClick={() => {
          setPersons(index + 1);
          setShowPersonsList(false);
        }}
      >
        {item}
      </div>
    ));
  };

  const closeCalendar = () => {
    setShowCheckInCalendar(false);
    setShowCheckOutCalendar(false);
    setShowPersonsList(false);
  };

  const showCheckIn = () => {
    setShowCheckInCalendar(!showCheckInCalendar);
    setShowCheckOutCalendar(false);
    setShowCheckInError(false);
    setShowPersonsList(false);
  };

  const showCheckOut = () => {
    setShowCheckOutCalendar(!showCheckOutCalendar);
    setShowCheckInCalendar(false);
    setShowCheckOutError(false);
    setShowPersonsList(false);
  };

  const showPersonList = () => {
    setShowPersonsList(!showPersonsList);
    setShowCheckInCalendar(false);
    setShowCheckOutCalendar(false);
  };

  return (
    <div className={styles.container}>
      {showCheckInCalendar || showCheckOutCalendar || showPersonsList ? (
        <div onClick={closeCalendar} className={styles.backdropBackground}></div>
      ) : null}
      <img className={styles.closeImage} src={images.booking.closeButton} onClick={() => setShowBooking(false)} />
      <span className={styles.title}>{t('bookingComponent.booking')}</span>
      <InvoicePage
        adress={adress}
        email={email}
        name={name}
        setName={setName}
        setEmail={setEmail}
        setAdress={setAdress}
        companyName={companyName}
        setCompanyName={setCompanyName}
        fiscalCode={fiscalCode}
        setFiscalCode={setFiscalCode}
        registrationNumber={registrationNumber}
        setRegistrationNumber={setRegistrationNumber}
        city={city}
        setCity={setCity}
        countryPre={countryPre}
        setCountryPre={setCountryPre}
        county={county}
        setCounty={setCounty}
        setShowBooking={setShowBooking}
        type={type}
        setType={setType}
        showNameError={showNameError}
        showEmailError={showEmailError}
        showTownError={showTownError}
        showRegionError={showRegionError}
        showAdressError={showAdressError}
        showCountryError={showCountryError}
        showFiscalCodeError={showFiscalCodeError}
        showRegNumberError={showRegNumberError}
        showCompanyNameError={showCompanyNameError}
        setShowNameError={setShowNameError}
        setShowEmailError={setShowEmailError}
        setShowTownError={setShowTownError}
        setShowRegionError={setShowRegionError}
        setShowAdressError={setShowAdressError}
        setShowFiscalCodeError={setShowFiscalCodeError}
        setShowRegNumberError={setShowRegNumberError}
        setShowCountryError={setShowCountryError}
        setShowCompanyNameError={setShowCompanyNameError}
      />
      <p className={styles.checkboxContainer}>
        <input type={'checkbox'} onClick={() => {
          setPersonalData(!personalData);
          setPersonalError(false)
        }} />
        <label className={personalError ? styles.checkBoxTextError : styles.checkBoxText}>{t('bookingComponent.personalData')}</label>
      </p>
      <p className={styles.checkboxContainer}>
        <input type='checkbox' onClick={() => setNewsChecked(!newsChecked)} />
        <label className={styles.checkBoxText}>{t('bookingComponent.newsAndOffers')}</label>
      </p>
      <p className={styles.checkBoxText}>*For every three selected nights, the fourth is on us!</p>
      <div className={styles.checkInComp}>
        <div className={styles.extenderWrapper}>
          <div className={styles.centerer}>
            <span onClick={showCheckIn} className={!showCheckInError ? styles.textCheck : styles.textCheckError}>
              {checkInString === 'undefined/undefined/undefined' || checkInString === ''
                ? t('checkinComponent.checkIn')
                : checkInString}
            </span>
            <img onClick={showCheckIn} className={styles.arrow} src={images.checkInComponent.arrowDown} />
            <div className={styles.rightStyle}></div>
            {showCheckInCalendar === true && (
              <div onClick={() => setShowCheckInCalendar(true)} className={styles.calendar}>
                <Calendar
                  value={checkInDates}
                  onChange={setCheckInDates}
                  colorPrimary={'#CAA987'}
                  colorPrimaryLight={'#F0EAE4'}
                  minimumDate={currentDate}
                  disabledDays={disabledDays}
                  calendarClassName={styles.calendarStyle}
                  customDaysClassName={checkInDaysCustomised}
                />
              </div>
            )}
          </div>
          <div className={styles.centerer}>
            <span onClick={showCheckOut} className={!showCheckOutError ? styles.textCheck : styles.textCheckError}>
              {checkOutString === 'undefined/undefined/undefined' || checkOutString === ''
                ? t('checkinComponent.checkout')
                : checkOutString}
            </span>
            <img onClick={showCheckOut} className={styles.arrow} src={images.checkInComponent.arrowDown} />
            <div className={styles.rightStyle}></div>
            {showCheckOutCalendar === true && (
              <div onClick={() => setShowCheckOutCalendar(true)} className={styles.calendar}>
                <Calendar
                  value={checkInDates}
                  onChange={setCheckInDates}
                  colorPrimary={'#CAA987'}
                  colorPrimaryLight={'#F0EAE4'}
                  minimumDate={currentDate}
                  disabledDays={disabledDays}
                  calendarClassName={styles.calendarStyle}
                  customDaysClassName={checkInDaysCustomised}
                />
              </div>
            )}
          </div>
          <div className={styles.centerer}>
            <span className={persons === 1 ? styles.textCheck : styles.textCheckMargin} onClick={showPersonList}>
              {persons} {t('checkinComponent.pers')}
            </span>
            {showPersonsList && <div className={styles.personsList}>{renderPersonList()}</div>}
            <div className={styles.flexColumn}>
              <img
                className={styles.arrow}
                src={images.checkInComponent.arrowUp}
                onClick={() => (persons < 4 ? setPersons(persons + 1) : null)}
              />
              <img
                className={styles.topper}
                src={images.checkInComponent.arrowDown}
                onClick={() => (persons > 1 ? setPersons(persons - 1) : null)}
              />
            </div>
          </div>
        </div>
      </div>
      {showNameError === true && <div className={styles.error}>{t('bookingComponent.error')}</div>}
      <div className={styles.buttonsContainer}>
        <button className={styles.cancelButton} onClick={() => setShowBooking(false)}>
          <span className={styles.buttonsText}>{t('bookingComponent.cancel')}</span>
        </button>
        <button className={styles.nextButton} onClick={handleNext}>
          <span className={styles.buttonsText}>{t('bookingComponent.next')}</span>
        </button>
      </div>
    </div>
  );
};

export default BookingComponent;
