import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './../components/InvoicePage.module.scss';
import { CountryDropdown } from 'react-country-region-selector';

interface IProps {
  setAdress: (adress: string) => void;
  setCompanyName: (companyname: string) => void;
  setRegistrationNumber: (registrationNumber: string) => void;
  setFiscalCode: (fiscalCode: string) => void;
  setCity: (city: string) => void;
  setCounty: (county: string) => void;
  setShowBooking: (showBooking: boolean) => void;
  setType: (type: string) => void;
  setEmail: (email: string) => void;
  setName: (name: string) => void;
  setCountryPre: (countryPre: string) => void;
  adress: string;
  companyName: string;
  registrationNumber: string;
  fiscalCode: string;
  city: string;
  county: string;
  email: string;
  name: string;
  type: string;
  countryPre: string;
  showNameError: boolean;
  setShowNameError: (item: boolean) => void;
  showEmailError: boolean;
  setShowEmailError: (item: boolean) => void;
  showTownError: boolean;
  setShowTownError: (item: boolean) => void;
  showRegionError: boolean;
  setShowRegionError: (item: boolean) => void;
  showAdressError: boolean;
  setShowAdressError: (item: boolean) => void;
  showCountryError: boolean;
  setShowCountryError: (item: boolean) => void;
  showCompanyNameError: boolean;
  setShowCompanyNameError: (item: boolean) => void;
  showFiscalCodeError: boolean;
  setShowFiscalCodeError: (item: boolean) => void;
  showRegNumberError: boolean;
  setShowRegNumberError: (item: boolean) => void;
}

const InvoicePage: React.FC<IProps> = ({
  setAdress,
  setCompanyName,
  setRegistrationNumber,
  setFiscalCode,
  setCity,
  setCounty,
  adress,
  companyName,
  registrationNumber,
  fiscalCode,
  city,
  county,
  type,
  setType,
  email,
  setEmail,
  name,
  setName,
  countryPre,
  setCountryPre,
  showNameError,
  setShowNameError,
  showEmailError,
  setShowEmailError,
  showTownError,
  setShowTownError,
  showRegionError,
  setShowRegionError,
  showAdressError,
  setShowAdressError,
  showCountryError,
  setShowCountryError,
  showCompanyNameError,
  setShowCompanyNameError,
  showFiscalCodeError,
  setShowFiscalCodeError,
  showRegNumberError,
  setShowRegNumberError,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.directionRow}>
        <input
          type="radio"
          id="individual"
          name="invoiceType"
          value="INDIVIDUAL"
          onClick={() => setType('individual')}
          defaultChecked
        />
        <label htmlFor="individual" className={styles.radioButtonLabel}>
          {t('invoicePage.individual')}
        </label>
        <input
          type="radio"
          id="entity"
          name="invoiceType"
          value="ENTITY"
          className={styles.radioButton}
          onClick={() => setType('entity')}
        />
        <label htmlFor="entity" className={styles.radioButtonLabel}>
        {t('invoicePage.organisation')}
        </label>
      </div>
      {type === 'individual' ? (
        <div>
          <input
            placeholder={t('invoicePage.placeholders.name')}
            type={!showNameError ? 'textBooking1' : 'textBookingError1'}
            className={styles.input}
            value={name}
            onChange={(event) => setName(event.target.value)}
            onSelect={() => setShowNameError(false)}
          />
          <input
            placeholder={t('invoicePage.placeholders.email')}
            type={!showEmailError ? 'textBooking1' : 'textBookingError1'}
            className={styles.input}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            onSelect={() => setShowEmailError(false)}
          />
          <div className={styles.doubleInputContainer}>
            <input
              placeholder={t('invoicePage.placeholders.town')}
              type={!showTownError ? 'textBookingHalf' : 'textBookingErrorHalf'}
              className={styles.input}
              value={city}
              onChange={(event) => setCity(event.target.value)}
              onSelect={() => setShowTownError(false)}
            />
            <input
              placeholder={t('invoicePage.placeholders.region')}
              type={!showRegionError ? 'textBookingHalf' : 'textBookingErrorHalf'}
              className={styles.input}
              value={county}
              onChange={(event) => setCounty(event.target.value)}
              onSelect={() => setShowRegionError(false)}
            />
          </div>
          <input
            placeholder={t('invoicePage.placeholders.fullAddress')}
            type={!showAdressError ? 'textBooking1' : 'textBookingError1'}
            className={styles.input}
            value={adress}
            onChange={(event) => setAdress(event.target.value)}
            onSelect={() => setShowAdressError(false)}
          />
        </div>
      ) : (
        <div className={styles.width100}>
          <input
            placeholder={t('invoicePage.placeholders.companyName')}
            type={!showCompanyNameError ? 'textBooking1' : 'textBookingError1'}
            className={styles.input}
            value={companyName}
            onChange={(event) => setCompanyName(event.target.value)}
            onSelect={() => setShowCompanyNameError(false)}
          />
          <div className={styles.doubleInputContainer}>
            <input
              placeholder={t('invoicePage.placeholders.fiscalCode')}
              type={!showFiscalCodeError ? 'textBookingHalf' : 'textBookingErrorHalf'}
              className={styles.input}
              value={fiscalCode}
              onChange={(event) => setFiscalCode(event.target.value)}
              onSelect={() => setShowFiscalCodeError(false)}
            />
            <input
              placeholder={t('invoicePage.placeholders.registrationNumber')}
              type={!showRegNumberError ? 'textBookingHalf' : 'textBookingErrorHalf'}
              className={styles.input}
              value={registrationNumber}
              onChange={(event) => setRegistrationNumber(event.target.value)}
              onSelect={() => setShowRegNumberError(false)}
            />
          </div>
          <input
            placeholder={t('invoicePage.placeholders.fullAddress')}
            type={!showAdressError ? 'textBooking1' : 'textBookingError1'}
            className={styles.input}
            value={adress}
            onChange={(event) => setAdress(event.target.value)}
            onSelect={() => setShowAdressError(false)}
          />
          <div className={styles.doubleInputContainer}>
            <input
              placeholder={t('invoicePage.placeholders.town')}
              type={!showTownError ? 'textBookingHalf' : 'textBookingErrorHalf'}
              className={styles.input}
              value={city}
              onChange={(event) => setCity(event.target.value)}
              onSelect={() => setShowTownError(false)}
            />
            <input
              placeholder={t('invoicePage.placeholders.region')}
              type={!showRegionError ? 'textBookingHalf' : 'textBookingErrorHalf'}
              className={styles.input}
              value={county}
              onChange={(event) => setCounty(event.target.value)}
              onSelect={() => setShowRegionError(false)}
            />
          </div>
          <input
            placeholder={t('invoicePage.placeholders.name')}
            type={!showNameError ? 'textBooking1' : 'textBookingError1'}
            className={styles.input}
            value={name}
            onChange={(event) => setName(event.target.value)}
            onSelect={() => setShowNameError(false)}
          />
          <input
            placeholder={t('invoicePage.placeholders.email')}
            type={!showEmailError ? 'textBooking1' : 'textBookingError1'}
            className={styles.input}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            onSelect={() => setShowEmailError(false)}
          />
        </div>
      )}
      <div>
        <CountryDropdown
          value={countryPre}
          onChange={(val) => (setCountryPre(val), setShowCountryError(false))}
          valueType="short"
          classes={showCountryError ? styles.countrySelectorError : countryPre === '' ? styles.countrySelector : styles.countrySelectorChecked}
          defaultOptionLabel={t('invoicePage.placeholders.country')}
        />
      </div>
    </div>
  );
};

export default InvoicePage;
