import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'dotenv/config'; // see https://github.com/motdotla/dotenv#how-do-i-use-dotenv-with-import
import ReactPixel from 'react-facebook-pixel';

import './i18n';

const advancedMatching = {
  em: '',
  ct: '',
  country: '',
  db: '',
  fn: '',
  ge: '',
  ln: '',
  ph: '',
  st: '',
  zp: '',
};

ReactPixel.init('1168387150692809', advancedMatching, {
  debug: true,
  autoConfig: false
});

ReactPixel.pageView();
ReactPixel.fbq('track', 'CompleteRegistration', 'Lead', 'Purchase');

ReactPixel.pageView();

ReactDOM.render(
  <React.StrictMode>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
