import React, { useEffect, useState, useCallback } from 'react';
import { Calendar, Day, DayRange, DayValue } from 'react-modern-calendar-datepicker';
import { useTranslation } from 'react-i18next';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { manualDeleteDate, manualReserveDate, verifyDates } from '../functions/firebase';
import { getCheckInDays, getDisabledDays } from '../functions/helpers';
import styles from './ManualReservations.module.scss';
import strings from './../data/strings.json';

interface DisabledDays {
  year: number;
  month: number;
  day: number;
}

interface CustomisedDays {
  year: number;
  month: number;
  day: number;
  className: any;
}

const ManualReservations = () => {
  const [disabledDays, setDisabledDays] = useState<DisabledDays[]>([]);
  const [checkInDates, setCheckInDates] = useState<DayRange>({
    from: null,
    to: null,
  });
  const [canceledDays, setCanceledDays] = useState<DayRange>({
    from: null,
    to: null,
  });
  const [showAddBox, setShowAddBox] = useState<boolean>(false);
  const [showDeleteBox, setShowDeleteBox] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [checkInDaysCustomised, setCheckInDaysCustomised] = useState<CustomisedDays[]>([]);
  const [checkInDays, setCheckInDays] = useState<Day[]>([]);

  const { t } = useTranslation();

  const getDatesBetweenDates = useCallback(() => {
    const startDate = checkInDates.from?.year + '/' + checkInDates.from?.month + '/' + checkInDates.from?.day;
    const endDate = checkInDates.to?.year + '/' + checkInDates.to?.month + '/' + checkInDates.to?.day;
    let dates: any = [];
    const lastDate = new Date(endDate);
    const theDate = new Date(startDate);
    while (theDate < lastDate) {
      dates = [...dates, new Date(theDate).toLocaleDateString('en-US')];
      theDate.setDate(theDate.getDate() + 1);
    }
    const datesArray: Day[] = dates.map((element) => {
      const tempArray = element.split('/');
      return { day: parseInt(tempArray[1]), month: parseInt(tempArray[0]), year: parseInt(tempArray[2]) };
    });
    checkInDays.map((element) => {
      datesArray.map((item) => {
        if (element.day === item.day && element.month === item.month && element.year === item.year) {
          setCheckInDates({ from: null, to: null });
        }
      });
    });
  }, [checkInDates]);

  const customizeCheckInDays = async () => {
    await getCheckInDays(setCheckInDays);
    const customizedDays = checkInDays.map((element) => {
      const item = { ...element, className: styles.checkInOnly };
      return item;
    });
    const customisedDisabled = disabledDays.map((element) => {
      const item = { ...element, className: styles.disabledDays };
      return item;
    });
    const customisedArray = customizedDays.concat(customisedDisabled);
    setCheckInDaysCustomised(customisedArray);
  };

  const currentDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };

  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;

  const reserveDates = async () => {
    await manualReserveDate(checkInDates);
    alert('Datele rezervate au fost adaugate!');
    setCheckInDates({
      from: null,
      to: null,
    });
    await getDisabledDays(setDisabledDays);
  };

  const deleteDates = async () => {
    await manualDeleteDate(canceledDays, false);
    alert('Datele rezervarilor au fost sterse cu succes!');
    setRefresh((prevState) => !prevState);
    setCanceledDays({
      from: null,
      to: null,
    });
    await getDisabledDays(setDisabledDays);
  };

  const checkBookingDays = useCallback(() => {
    if (checkInDates.from && checkInDates.to) {
      const endDate = checkInDates.to?.year + '-' + checkInDates.to?.month + '-' + checkInDates.to?.day;
      if (
        checkInDates.from.day === checkInDates.to.day &&
        checkInDates.from.month === checkInDates.to.month &&
        checkInDates.from.year === checkInDates.to.year
      ) {
        const endDateInMs = new Date(endDate);
        const newEndDate = endDateInMs.setDate(endDateInMs.getDate() + 1);
        const newEndDateFormatted = new Date(newEndDate);
        const newEndDateString = newEndDateFormatted.toLocaleDateString('en-US');
        const newEndDateArray = newEndDateString.split('/');
        const nextDayDisabled = disabledDays.some(
          (element) =>
            element.day === parseInt(newEndDateArray[1]) &&
            element.month === parseInt(newEndDateArray[0]) &&
            element.year === parseInt(newEndDateArray[2]),
        );
        if (!nextDayDisabled)
          setCheckInDates({
            ...checkInDates,
            to: {
              day: parseInt(newEndDateArray[1]),
              month: parseInt(newEndDateArray[0]),
              year: parseInt(newEndDateArray[2]),
            },
          });
        else setCheckInDates({ from: null, to: null });
      }
    }
  }, [checkInDates]);

  useEffect(() => {
    if (checkInDaysCustomised.length < 1) customizeCheckInDays();
  }, [disabledDays]);

  useEffect(() => {
    getDatesBetweenDates();
    getDisabledDays(setDisabledDays);
    checkBookingDays();
  }, [checkInDates]);

  return (
    <div>
      {showAddBox ? (
        <div className={styles.askReserveModal}>
          <div className={styles.reserveMainText}>{t('manualReservation.pressReserve')}</div>
          <div className={styles.flexRow}>
            <button className={styles.reserveButton} onClick={reserveDates}>
              <span className={styles.reserveText}>{t('manualReservation.reserve')}</span>
            </button>
            <button className={styles.reserveButton} onClick={() => setShowAddBox(false)}>
              <span className={styles.reserveText}>{t('manualReservation.cancel')}</span>
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className={styles.calendarButtonContainer}>
        <Calendar
          value={checkInDates}
          onChange={setCheckInDates}
          colorPrimary={'#CAA987'}
          colorPrimaryLight={'#F0EAE4'}
          minimumDate={currentDate}
          disabledDays={disabledDays}
          calendarClassName={styles.addCalendar}
          customDaysClassName={checkInDaysCustomised}
          renderFooter={() => <div className={styles.calendarFooter}>{t('manualReservation.calendarAdaugare')}</div>}
        ></Calendar>
        <button className={styles.buttonStyle} onClick={() => setShowAddBox(true)}>
          <span className={styles.buttonText}>{t('manualReservation.adaugaRezervare')}</span>
        </button>
      </div>
      {showDeleteBox ? (
        <div className={styles.askReserveModal}>
          <div className={styles.reserveMainText}>{t('manualReservation.pressDelete')}</div>
          <div className={styles.flexRow}>
            <button className={styles.reserveButton} onClick={() => deleteDates()}>
              <span className={styles.reserveText}>{t('manualReservation.delete')}</span>
            </button>
            <button className={styles.reserveButton} onClick={() => setShowDeleteBox(false)}>
              <span className={styles.reserveText}>{t('manualReservation.cancel')}</span>
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <div className={styles.calendarButtonContainer}>
        <Calendar
          value={canceledDays}
          onChange={setCanceledDays}
          colorPrimary={'#CAA987'}
          colorPrimaryLight={'#F0EAE4'}
          minimumDate={currentDate}
          calendarClassName={styles.addCalendar}
          renderFooter={() => <div className={styles.calendarFooter}>{t('manualReservation.calendarStergere')}</div>}
        ></Calendar>
        <button className={styles.buttonStyle} onClick={() => setShowDeleteBox(true)}>
          <span className={styles.buttonText}>{t('manualReservation.stergereRezervare')}</span>
        </button>
      </div>
    </div>
  );
};

export default ManualReservations;
