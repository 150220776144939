import React from 'react';
import { useTranslation } from 'react-i18next';

import images from '../data/images';
import styles from './OnePriceMiddleComponent.module.scss';
import TheSeparator from './TheSeparator';

const OnePriceMiddleComponent = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.mainContainer}>
      <div className={styles.topContainer}>
        <div className={styles.checkOutText}>{t('offersSection.checkOut')}</div>
      </div>
      <img className={styles.ziggerImage} src={images.offers.horizontalZigger}></img>
      <div className={styles.bottomContainer}>
        <div className={styles.checkOutText}>{t('offersSection.priceFor')}</div>
        <div className={styles.daysText}>{t('offersSection.sunday')}</div>
        <div className={styles.sepLine}></div>
        <div className={styles.priceText}>{t('offersSection.price')}</div>
      </div>
    </div>
  );
};

export default OnePriceMiddleComponent;
