import { Email } from 'react-html-email';

export const getClientSecret = async (numberOfDays: number, currency: string) => {
  return await fetch('https://us-central1-oasisresidenceweb-b7f37.cloudfunctions.net/createPayment', {
    method: 'POST',
    body: JSON.stringify({ numberOfDays: numberOfDays, currency: currency }),
  })
    .then((res) => res.json())
    .then((data) => data.clientSecret);
};

export const getClientSecretTest = async (numberOfDays: number, currency: string) => {
  return await fetch('https://us-central1-oasisresidencewebdev.cloudfunctions.net/createPaymentTest/', {
    method: 'POST',
    body: JSON.stringify({ numberOfDays: numberOfDays, currency: currency }),
  })
    .then((res) => res.json())
    .then((data) => data.clientSecret);
};

export const createSessionTest = async (numberOfDays: number, email: string, reservationDate: string, docId: string) => {
  return await fetch('https://us-central1-oasisresidencewebdev.cloudfunctions.net/createSessionTest/', {
    method: 'POST',
    body: JSON.stringify({ numberOfDays: numberOfDays, email: email, reservationDate: reservationDate, docId: docId }),
  })
    .then((res) => res.json())
    .then((data) => data.url)
    .catch((err) => console.log(err, 'error'));
};

export const createSession = async (numberOfDays: number, email: string, reservationDate: string, docId: string) => {
  return await fetch('https://us-central1-oasisresidenceweb-b7f37.cloudfunctions.net/createSession/', {
    method: 'POST',
    body: JSON.stringify({ numberOfDays: numberOfDays, email: email, reservationDate: reservationDate, docId: docId }),
  })
    .then((res) => res.json())
    .then((data) => data.url)
    .catch((err) => console.log(err, 'error'));
};

export const createInvoiceIndividual = async (
  name: string,
  numberOfDays: number,
  reservationDate: string,
  countryPre: string,
  address: string,
  city: string,
  county: string,
  email: string,
) => {
  return await fetch('https://us-central1-oasisresidencewebdev.cloudfunctions.net/createInvoiceIndividual', {
    method: 'POST',
    body: JSON.stringify({
      name: name,
      numberOfDays: numberOfDays,
      reservationDate: reservationDate,
      countryPre: countryPre,
      address: address,
      city: city,
      county: county,
      email: email,
    }),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => console.log(err, 'error'));
};

export const createInvoiceOrganisation = async (
  companyName: string,
  numberOfDays: number,
  reservationDate: string,
  countryPre: string,
  address: string,
  city: string,
  county: string,
  fiscalCode: string,
  regNumber: string,
  email: string,
) => {
  return await fetch('https://us-central1-oasisresidencewebdev.cloudfunctions.net/createInvoiceOrganisation', {
    method: 'POST',
    body: JSON.stringify({
      email: email,
      companyName: companyName,
      numberOfDays: numberOfDays,
      reservationDate: reservationDate,
      countryPre: countryPre,
      address: address,
      city: city,
      county: county,
      fiscalCode: fiscalCode,
      regNumber: regNumber,
    }),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => console.log(err, 'error'));
};


export const createInvoiceIndividualLive = async (
  name: string,
  numberOfDays: number,
  reservationDate: string,
  countryPre: string,
  address: string,
  city: string,
  county: string,
  email: string,
) => {
  return await fetch('https://us-central1-oasisresidenceweb-b7f37.cloudfunctions.net/createInvoiceIndividualLive', {
    method: 'POST',
    body: JSON.stringify({
      name: name,
      numberOfDays: numberOfDays,
      reservationDate: reservationDate,
      countryPre: countryPre,
      address: address,
      city: city,
      county: county,
      email: email,
    }),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => console.log(err, 'error'));
};

export const createInvoiceOrganisationLive = async (
  companyName: string,
  numberOfDays: number,
  reservationDate: string,
  countryPre: string,
  address: string,
  city: string,
  county: string,
  fiscalCode: string,
  regNumber: string,
  email: string,
) => {
  return await fetch('https://us-central1-oasisresidenceweb-b7f37.cloudfunctions.net/createInvoiceOrganisationLive', {
    method: 'POST',
    body: JSON.stringify({
      email: email,
      companyName: companyName,
      numberOfDays: numberOfDays,
      reservationDate: reservationDate,
      countryPre: countryPre,
      address: address,
      city: city,
      county: county,
      fiscalCode: fiscalCode,
      regNumber: regNumber,
    }),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((err) => console.log(err, 'error'));
};



