import { useTranslation } from 'react-i18next';
import images from '../data/images';
import styles from './../components/ThankYouNewsletter.module.scss';
import strings from './../data/strings.json';

interface IProps {
  setShowThankYou: (showPayment: boolean) => void;
}

const ThankYouNewsletter: React.FC<IProps> = ({ setShowThankYou }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.emailResponseBoxBackground}>
      <div className={styles.emailResponseBox}>
        <img className={styles.closedEmailImage} src={images.newsletter.closedEmail} />
        <div>
          <div className={styles.emailBoxText}>{t('thankYou.thankYou')}</div>
        </div>
        <button className={styles.newsButton} onClick={() => setShowThankYou(false)}>
          <span className={styles.emailBoxClose}>{strings.contactUs.email.close}</span>
        </button>
      </div>
    </div>
  );
};

export default ThankYouNewsletter;
