import { useState } from 'react';
import { HashLink as Link, NavHashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

import styles from './../components/NavBarResidence.module.scss';


const MenuMobile = () => {
  const [BurgerOpen, setBurgerOpen] = useState(false);
  const { t } = useTranslation();
  const burgerClickHandler = (event: boolean) => {
    setBurgerOpen(!BurgerOpen);
  };
  const closeBurger = () => {
    setBurgerOpen(false);
  };

  return (
    <>
      {BurgerOpen ? <div onClick={closeBurger} className={styles.backdropBackground}></div> : null}
      <div
        className={styles.burgerElement}
        onClick={() => {
          burgerClickHandler(!BurgerOpen);
        }}
      >
        <div className={BurgerOpen ? `${styles.bar1} ${styles.bar1End}` : `${styles.bar1}`}></div>
        <div className={BurgerOpen ? `${styles.bar2} ${styles.bar2End}` : `${styles.bar2}`}></div>
        <div className={BurgerOpen ? `${styles.bar3} ${styles.bar3End}` : `${styles.bar3}`}></div>
      </div>
      <div className={BurgerOpen ? `${styles.burgerMenu} ${styles.activeBurger} ` : `${styles.burgerMenu}`}>
        <ul className={styles.ulMobile}>
          <li className={styles.liMobile}>
            <NavHashLink className={styles.HashLinkStyle} to="#aboutus">
              {t('navMenu.aboutUs')}
            </NavHashLink>
          </li>
          <li className={styles.liMobile}>
            <NavHashLink className={styles.HashLinkStyle} to="#gallery">
              {t('navMenu.gallery')}
            </NavHashLink>
          </li>
          <li className={styles.liMobile}>
            <NavHashLink className={styles.HashLinkStyle} to="#offers">
              {t('navMenu.offers')}
            </NavHashLink>
          </li>
          <li className={styles.liMobile}>
            <NavHashLink className={styles.HashLinkStyle} to="#reviews">
              {t('navMenu.reviews')}
            </NavHashLink>
          </li>
          <li className={styles.liMobile}>
            <NavHashLink className={styles.HashLinkStyle} to="#contactus">
              {t('navMenu.contactUs')}
            </NavHashLink>
          </li>
          <li className={styles.liMobile}>
            <NavHashLink className={styles.HashLinkStyle} to="#contactus">
              {t('navMenu.location')}
            </NavHashLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MenuMobile;
