import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';

import HeaderMessage from '../components/HeaderMessage';
import ReviewCard from '../components/ReviewCard';
import DragItem from '../components/DragItem/DragItem';

import styles from './Reviews.module.scss';
import images from './../data/images';

import TheSeparator from '../components/TheSeparator';
import { useState } from 'react';

const Reviews = () => {
  const [CardIndex, setCardIndex] = useState(2);
  const { t } = useTranslation();
  const [coord, setCoord] = useState({ x: 0, y: 0 });
  const handleMouseMove = (e) => {
    setCoord({ x: e.screenX, y: e.screenY });
  };

  const testimonials = {
    casandra: {
      authorName: t('reviewsSection.reviewersList.casandra.authorName'),
      testimonialText: t('reviewsSection.reviewersList.casandra.testimonialText'),
      firstLetter: 'C',
    },
    mihai: {
      authorName: t('reviewsSection.reviewersList.mihai.authorName'),
      testimonialText: t('reviewsSection.reviewersList.mihai.testimonialText'),
      firstLetter: 'M',
    },
    madalina_victoria: {
      authorName: t('reviewsSection.reviewersList.madalina_victoria.authorName'),
      testimonialText: t('reviewsSection.reviewersList.madalina_victoria.testimonialText'),
      firstLetter: 'M',
    },
    tomasz: {
      authorName: t('reviewsSection.reviewersList.tomasz.authorName'),
      testimonialText: t('reviewsSection.reviewersList.tomasz.testimonialText'),
      firstLetter: 'T',
    },
    victor: {
      authorName: t('reviewsSection.reviewersList.victor.authorName'),
      testimonialText: t('reviewsSection.reviewersList.victor.testimonialText'),
      firstLetter: 'V',
    },
  };

  let limitLoop = 4;
  const tapHandler = (indexIncrement: number) => {
    setCardIndex(indexIncrement);
  };
  const sliderHandler = (data: number) => {
    if (data !== 0) {
      if (data > 50) {
        internNotifyHandler(1);
      }
      if (data < 50) {
        internNotifyHandler(-1);
      }
    } else {
      if (window.screen.width > 992)
        if (coord.x > window.screen.width / 2) internNotifyHandler(1);
        else internNotifyHandler(-1);
    }
  };
  const internNotifyHandler = (value: number) => {
    ReactGA.event('User scrolled in reviews');

    if (window.screen.availWidth < 428) {
      if (CardIndex + value >= limitLoop + 1 || CardIndex + value === -1) return;
    } else {
      if (CardIndex + value >= limitLoop || CardIndex + value === 0) return;
    }

    setCardIndex(CardIndex + value);
  };

  return (
    <>
      <TheSeparator anchorID="reviews" />
      <div className={styles.reviewContainer} onMouseMove={handleMouseMove}>
        <div className={styles.headerContainer}>
          <HeaderMessage headTitle={t('reviewsSection.headTitle')} headDescription={t('reviewsSection.smallDescription')} />
        </div>
        <div className={styles.reviewSection}>
          <DragItem sliderActiveCoords={sliderHandler} />
          <div className={styles.reviewWall}>
            <div
              className={`${styles.sliderSpace} ${
                CardIndex === 0
                  ? styles.zeroSlide
                  : CardIndex === 1
                  ? styles.oneSlide
                  : CardIndex === 2
                  ? styles.twoSlide
                  : CardIndex === 3
                  ? styles.threeSlide
                  : CardIndex === 4
                  ? styles.forthSlide
                  : ''
              }`}
            >
              <div className={styles.interiorPadder}>
                <ReviewCard
                  styler={CardIndex === 0 ? 'onFocus' : CardIndex >= 2 ? 'hide' : ''}
                  testimonials={testimonials.casandra}
                  profilePicture={images.reviewsComponent.casandra}
                />
                <ReviewCard
                  styler={CardIndex === 1 ? 'onFocus' : CardIndex >= 3 ? 'hide' : ''}
                  testimonials={testimonials.mihai}
                  profilePicture={images.reviewsComponent.mihai}
                />
                <ReviewCard
                  styler={CardIndex === 2 ? 'onFocus' : CardIndex >= 4 ? 'hide' : ''}
                  testimonials={testimonials.madalina_victoria}
                  profilePicture={images.reviewsComponent.madalina_victoria}
                />
                <ReviewCard
                  styler={CardIndex === 3 ? 'onFocus' : CardIndex >= 5 || CardIndex <= 1 ? 'hide' : ''}
                  testimonials={testimonials.tomasz}
                  profilePicture={images.reviewsComponent.tomasz}
                />
                <ReviewCard
                  styler={CardIndex === 4 ? 'onFocus' : CardIndex === 2 || CardIndex <= 2 ? 'hide' : ''}
                  testimonials={testimonials.victor}
                  profilePicture={images.reviewsComponent.victor}
                />
              </div>
            </div>
          </div>
          <div className={styles.actionPanel}>
            <img
              onClick={() => {
                tapHandler(0);
              }}
              className={`${styles.inactive} ${styles.mobileOnly}`}
              src={CardIndex != 0 ? images.review.roundButtonInactive : images.review.roundButton}
            />
            <img
              onClick={() => {
                tapHandler(1);
              }}
              className={styles.inactive}
              src={CardIndex != 1 ? images.review.roundButtonInactive : images.review.roundButton}
            />
            <img
              onClick={() => {
                tapHandler(2);
              }}
              className={styles.active}
              src={CardIndex != 2 ? images.review.roundButtonInactive : images.review.roundButton}
            />
            <img
              onClick={() => {
                tapHandler(3);
              }}
              className={styles.inactive}
              src={CardIndex != 3 ? images.review.roundButtonInactive : images.review.roundButton}
            />
            <img
              onClick={() => {
                tapHandler(4);
              }}
              className={`${styles.inactive} ${styles.mobileOnly}`}
              src={CardIndex != 4 ? images.review.roundButtonInactive : images.review.roundButton}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Reviews;
