export const TermsAndCond = () => {
  return {
    __html: `<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
    text-align: center;
  "
>
  <strong><span style="font-size: 24px; line-height: 107%; font-family: \'Times New Roman\', serif">GDPR</span></strong>
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
    text-align: center;
  "
>
  <strong><span style="font-size: 24px; line-height: 107%; font-family: \'Times New Roman\', serif">&nbsp;</span></strong>
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <strong
    ><span style="font-size: 19px; line-height: 107%; font-family: \'Times New Roman\', serif"
      >Operatorul care prelucrează datele tale cu caracter personal</span
    ></strong
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >Societatea SC OASIS SRL , av&acirc;nd sediul situat &icirc;n BAIA MARE, str. A.P. CEHOV, nr. 21, Județul MARAMURES&ndash;
    Rom&acirc;nia, &icirc;nregistrată la Registrul Finanțelor J 24/2216/1994, cod de identificare fiscală RO 6793614, număr de
    telefon 0747 057 615, adresă de e-mail: oasisresidence2020@gmail.com</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <strong><span style="font-size: 19px; line-height: 107%; font-family: \'Times New Roman\', serif">Definiții</span></strong>
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >CONSIMȚĂM&Acirc;NT = &icirc;nseamnă orice manifestare de voință liberă, specifică, informată și lipsită de ambiguitate a
    persoanei vizate prin care aceasta acceptă, printr-o declarație sau printr-o acțiune fără echivoc, ca datele cu caracter
    personal care o privesc să fie prelucrate;</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >DATE CU CARACTER PERSONAL = &icirc;nseamnă orice informații privind o persoană fizică identificată sau identificabilă
    (&bdquo;persoana vizată&rdquo;); o persoană fizică identificabilă este o persoană care poate fi identificată, direct sau
    indirect, &icirc;n special prin referire la un element de identificare, cum ar fi un nume, un număr de identificare, date de
    localizare, un identificator online, sau la unul sau mai multe elemente specifice, proprii identității sale fizice,
    fiziologice, genetice, psihice, economice, culturale sau sociale;</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >REGULAMENTUL GDPR = &icirc;nseamnă Regulamentul (UE) 2016/679 din 27 aprilie 2016 privind protecția persoanelor fizice
    &icirc;n ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date și de abrogare
    a Directivei 95/46/CE (Regulamentul general privind protecția datelor);</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >PRELUCRAREA DATELOR = &icirc;nseamnă orice operațiune sau set de operațiuni efectuate asupra datelor cu caracter personal sau
    asupra seturilor de date cu caracter personal, cu sau fără utilizarea de mijloace automatizate, cum ar fi colectarea,
    &icirc;nregistrarea, organizarea, structurarea, stocarea, adaptarea sau modificarea, extragerea, consultarea, utilizarea,
    divulgarea prin transmitere, diseminarea sau punerea la dispoziție &icirc;n orice alt mod, alinierea sau combinarea,
    restricționarea, ștergerea sau distrugerea;</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >PERSOANĂ VIZATĂ = &icirc;nseamnă orice persoană fizică identificată sau identificabilă ale cărei date sunt prelucrate de
    către noi &icirc;n calitate de operator, ca de exemplu potențiali clienți sau vizitatori ai site-ului;</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >NEWSLETTER = &icirc;nseamnă un buletin periodic comunicat utilizatorilor care și-au exprimat consimțăm&acirc;ntul pentru a
    primi astfel de comunicări. Acesta cuprinde știri, informații despre activitățile, proiectele și noutățile firmei
    noastre;</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >SPAM = &icirc;nseamnă orice comunicare comerciala nesolicitată interzisa de lege.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <strong
    ><span style="font-size: 19px; line-height: 107%; font-family: \'Times New Roman\', serif"
      >Care este calitatea dvs. &icirc;n temeiul prezentei politici de confidențialitate?</span
    ></strong
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >Orice persoană fizică care utilizează site-ul, canalele noastre de social media este considerată o persoană vizată.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <strong
    ><span style="font-size: 19px; line-height: 107%; font-family: \'Times New Roman\', serif">Ce date prelucrăm?</span></strong
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >&Icirc;n funcție de funcționalitățile de care doriți să beneficiați, va trebui să prelucrăm anumite date personale, care,
    &icirc;n general, vor fi după caz următoarele:</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- datele de identificare (nume, prenume, adresa de e-mail, număr de telefon). &Icirc;n cazul in care ne veți furniza numărul
    dvs. de telefon, putem utiliza acest număr pentru a va comunica prin SMS, confirmări sau modificări ale
    &icirc;nt&acirc;lnirilor / programărilor pentru consultanța juridică stabilite cu noi.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- datele de facturare (nume, prenume, adresă, adresă de e-mail, valoarea serviciilor, modalitatea de plată, serviciile
    solicitate, data solicitării lor)</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- datele pentru marketing direct: nume, prenume, adresa de e-mail, istoricul navigării pe site, preferințele dvs. &icirc;n
    ceea ce privește comunicarea newsletter-ului și a materialelor gratuite.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- datele privind conectarea, geo-localizarea și navigația (&icirc;n cazul &icirc;n care interacționați cu noi, de exemplu, de
    pe telefonul mobil);</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- informații și conținut furnizat de dumneavoastră (&icirc;ntrebări, solicitări);</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- informații furnizate de dumneavoastră prin intermediul rețelelor de socializare (nume / alias utilizat pe rețeaua de
    socializare, fotografia de profil utilizată, like-urile, locația și orice altă informație descrisă pe rețeaua de socializare
    c&acirc;nd dumneavoastră utilizați aceste rețele pentru a relaționa cu noi).</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >Putem obține datele dvs. din mai multe surse, cum ar fi datele furnizate de dvs. prin intermediul site-ului prin formularul
    de contact, prin intermediul paginilor de social media pe care le accesezi, dar și prin intermediul instrumentului de Google
    Analytics prin care putem vedea modul &icirc;n care interacționezi cu site-ul nostru, pentru a &icirc;mbunătăți experiența
    dvs. pe site, dar și pentru a avea o statistică a utilizatorilor interesați de serviciile sau articolele noastre.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <strong
    ><span style="font-size: 19px; line-height: 107%; font-family: \'Times New Roman\', serif"
      >&Icirc;n ce scop prelucrăm datele dvs. cu caracter personal?</span
    ></strong
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >&Icirc;n funcție de modul &icirc;n care interacționați cu site-ul nostru, vă vom prelucra datele personale &icirc;n
    următoarele scopuri:</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- Pentru comunicarea cu tine, &icirc;n funcție de ce tip de serviciu ne-ai solicitat, pentru a-ți oferi consultanță pe
    marginea solicitării tale</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- Pentru gestionarea relației de colaborare &icirc;ntre tine și echipa noastră &ndash; contabilitate, facturare, plăți</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- Pentru respectarea obligațiilor legale pe care le avem de păstrare a evidențelor,</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- Pentru a preveni sau combate orice atac cibernetic sau amenințare asupra sistemelor noastre,</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- &Icirc;n scop de marketing, &icirc;n cazul &icirc;n care te-ai abonat la newsletter-ul nostru, vom utiliza datele tale
    &icirc;n scopul furnizării serviciului de newsletter, precum și &icirc;n scopul efectuării unei analize de profil pentru a
    determina care sunt preferințele tale pe baza istoricului de navigare pe site-ul nostru și pentru a stabili ce tip de
    materiale gratuite ți se potrivesc, &icirc;n funcție de interesele și căutările tale.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- Pentru a comunica cu tine prin intermediul rețelelor de socializare</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- Pentru a opera, evalua și &icirc;mbunătății serviciile noastre și experiența dumneavoastră &icirc;n relația cu site-ul și
    canalele de social media (incluz&acirc;nd dezvoltarea unor noi nișe de servicii solicitate de dumneavoastră,
    &icirc;mbunătățirea serviciilor deja oferite, managementul relațiilor de comunicare cu utilizatorii, analiza serviciilor
    noastre, efectuarea de analize de date și analize de audit)</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- Pentru a ne putea conforma cu cerințele legale.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <strong
    ><span style="font-size: 19px; line-height: 107%; font-family: \'Times New Roman\', serif"
      >Care este temeiul legal &icirc;n baza căruia prelucrăm datele?</span
    ></strong
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >Vom prelucra datele dvs. &icirc;n baza următoarelor temeiuri legale:</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- Pe baza consimțăm&acirc;ntului tău (&icirc;n conformitate Art. 6 alin. (1) lit. (a) din Regulamentul GDPR) &ndash;
    c&acirc;nd ești de acord să primești din partea noastră conținut relevant și personalizat, pentru a primi newsletter de la
    noi.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- Pentru &icirc;ncheierea și executarea unui contract (in conformitate cu art. 6 alin. (1) lit. (b) din Regulamentul GDPR)
    &ndash; spre exemplu &icirc;n faza pre-contractuală, la solicitarea dumneavoastră, este nevoie de prelucrarea anumitor date cu
    caracter personal &icirc;n vederea &icirc;ncheierii contractului.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- Pentru &icirc;ndeplinirea unei sarcini care servește unui interes public ( &icirc;n conformitate cu Art. 6 alin. (1) lit.
    (e) din Regulamentul GDPR).&Icirc;n conformitate cu articolul 39 din Legea nr. 51/1995, &icirc;n exercitarea profesiei,
    avocații sunt parteneri indispensabili ai justiției. Prin urmare, activitatea profesională a avocatului se exercită &icirc;n
    scopul &icirc;nfăptuirii justiției, servind astfel unui interes public.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- &Icirc;n scopul unui interes legitim (Art. 6 alin. (1) lit.(f) din Regulamentul GDPR) &ndash; inclusiv atunci c&acirc;nd
    avem un interes legitim &icirc;n efectuarea activităților de marketing, activități de cercetare, analize de date, management
    intern, procesarea și punerea &icirc;n executare a solicitărilor legale, desfășurarea activității noastre &icirc;n
    conformitate cu legislația aplicabilă.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <strong
    ><span style="font-size: 19px; line-height: 107%; font-family: \'Times New Roman\', serif"
      >C&acirc;t timp vom păstra datele dvs.?</span
    ></strong
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >Datele dvs. vor fi păstrate numai pentru perioada necesară pentru a răspunde la solicitarea dvs., &icirc;n cazul &icirc;n
    care avem mandat din partea dvs. pentru a va asista sau reprezenta &icirc;n fața autorităților sau instanțelor, vom păstra
    datele p&acirc;nă la soluționarea definitivă a procedurilor, urm&acirc;nd ca ulterior să ștergem datele sau să le arhivăm,
    &icirc;n funcție de prevederile legii aplicabile.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >Datele privind facturarea sunt șterse după 1 an, &icirc;n conformitate cu reglementările &icirc;n vigoare.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >&Icirc;n ceea ce privește datele prelucrate &icirc;n scopuri de marketing, acestea se vor șterge odată cu exprimarea opțiunii
    dvs. pentru dezabonare.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >După ce aceste perioade de păstrare expiră și dacă nu există un alt temei legal pentru care trebuie să păstrăm/prelucrăm
    datele tale acestea se vor șterge din sisteme noastre.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <strong><span style="font-size: 19px; line-height: 107%; font-family: \'Times New Roman\', serif">&nbsp;</span></strong>
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <strong><span style="font-size: 19px; line-height: 107%; font-family: \'Times New Roman\', serif">&nbsp;</span></strong>
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <strong
    ><span style="font-size: 19px; line-height: 107%; font-family: \'Times New Roman\', serif"
      >Transferuri de date către terțe părți</span
    ></strong
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >Vom transmite datele dvs., doar c&acirc;nd primim instrucțiunea sau permisiunea dvs. ori c&acirc;nd legea sau anumite
    instituții sau autorități ne solicită să facem astfel.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >Putem transfera datele dvs. &icirc;n afara Rom&acirc;niei. &Icirc;n cazul &icirc;n care destinatarii sunt stabiliți &icirc;n
    afara Spațiului Economic European vom verifica dacă există hotăr&acirc;ri, legi care recunosc faptul că aceste țări oferă un
    nivel adecvat de protecție a datelor.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <strong
    ><span style="font-size: 19px; line-height: 107%; font-family: \'Times New Roman\', serif"
      >Prelucrarea datelor tale pe rețelele de socializare</span
    ></strong
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >Putem avea acces la profilul tău și la datele publice din profil, precum și la comentariile postate de tine, dacă alegi să ne
    dai like, share sau să ne lași comentarii pe social media.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >Vom avea acces și la conținutul mesajelor private pe care ni le trimiți, &icirc;n cazul &icirc;n care alegi să ne scrii
    &icirc;n privat pe paginile de social media.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >Avem acces la statisticile generale cu privire la interacțiunea ta cu paginile noastre de social media.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >Toate aceste date sunt păstrate de noi pentru perioada necesară promovării echipei și serviciilor noastre, p&acirc;nă la
    &icirc;nchiderea paginilor de pe rețelele de socializare.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >Scopul prelucrării acestor date este promovarea echipei noastre, a serviciilor noastre, pentru a răspunde la mesajele pe care
    le primim prin intermediul social media, pentru analiza statisticilor și a interacțiunii tale cu paginile noastre de social
    media, dar și pentru a personaliza campaniile de publicitate.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >Temeiul legal al acestei prelucrări de date reprezintă interesul nostru legitim de a promova echipa și serviciile noastre, de
    a păstra interacțiunea cu utilizatorii, de a răspunde la mesajele primite prin aceste canale de comunicare și pentru a avea o
    măsura &icirc;n privința modului &icirc;n care dau rezultate paginile noastre de socializare.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >Rețelele de social media creează anumite profiluri ale utilizatorilor, utiliz&acirc;nd datele tale, iar &icirc;n ceea ce
    privește transferul datelor, o parte din datele de pe social media transmise de tine pot ajunge &icirc;n afara Spațiului
    Economic European, &icirc;n conformitate cu Termeni și Condițiile Google, Facebook și/sau LinkedIn.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <strong
    ><span style="font-size: 19px; line-height: 107%; font-family: \'Times New Roman\', serif"
      >Drepturile dvs. &icirc;n legătură cu datele dvs.</span
    ></strong
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >&Icirc;n conformitate cu Regulamentul GDPR aveți următoarele drepturi, &icirc;n ceea ce privește datele dvs. cu caracter
    personal pe care noi le prelucrăm:</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- Dreptul de a fi informat cu privire la prelucrarea datelor dvs.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- Dreptul de acces asupra datelor &ndash; Ne puteți solicita acces la datele dvs. cu caracter personal pe care noi le
    prelucrăm.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- Dreptul de a rectifica datele inexacte sau incomplete &ndash; Ne puteți solicita să rectificăm datele dvs. cu caracter
    personal pe care noi le avem deja.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- Dreptul la ștergere (&bdquo;dreptul de a fi uitat&rdquo;) &ndash; Ne puteți solicita să vă ștergem datele dvs. cu caracter
    personal, &icirc;n cazul &icirc;n care nu mai avem un alt temei legal pentru prelucrarea lor.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- Dreptul la restricționarea prelucrării &ndash; Ne puteți solicita să suspendăm o perioadă prelucrarea datelor dvs. cu
    caracter personal.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- Dreptul de a transmite datele pe care le avem despre dvs. către alt operator &ndash; Ne puteți solicita să transmitem
    datele dvs. cu caracter personal către un alt operator.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- Dreptul de a vă opune prelucrării datelor &ndash; &Icirc;n cazul &icirc;n care avem consimțăm&acirc;ntul pentru prelucrarea
    datelor dvs. &icirc;n orice scop, aveți, dreptul să &icirc;l retrageți &icirc;n orice moment.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- Dreptul de a nu face obiectul unei decizii bazate exclusiv pe prelucrarea automată, inclusiv crearea de profiluri cu efecte
    juridice sau cu efecte semnificative similare asupra dvs.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- Dreptul de a vă adresa justiției pentru apărarea drepturilor și intereselor dvs.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >- Dreptul de a formula o pl&acirc;ngere &icirc;n fața unei Autorități de Supraveghere.</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif">&nbsp;</span>
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter Personal</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >Adresă: B-dul G-ral. Gheorghe Magheru 28-30, Sector 1, cod poștal 010336, București, Rom&acirc;nia,</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >tel. +40.318.059.211; +40.318.059.212, fax: +40.318.059.220, anspdcp@dataprotection.ro, www.dataprotection.ro</span
  >
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif">&nbsp;</span>
</p>
<p
  style="
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 8pt;
    margin-left: 0cm;
    line-height: 107%;
    font-size: 15px;
    font-family: \'Calibri\', sans-serif;
  "
>
  <span style="font-size: 16px; line-height: 107%; font-family: \'Times New Roman\', serif"
    >&Icirc;n cazul &icirc;n care doriți să vă exercitați oricare din aceste drepturi, vă rugăm să ne adresați o solicitare
    &icirc;n acest sens la adresa de e-mail: oasisresidence2020@gmail.com</span
  >
</p>`,
  };
};

export default TermsAndCond;
