import React, { useState } from 'react';

import { NavHashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

import styles from './Footer.module.scss';
import images from './../data/images';
import strings from './../data/strings.json';
import { addEmailToList } from '../functions/firebase';
import { sendEmailForNewsletter } from '../services/emails';
import ThankYouNewsletter from '../components/ThankYouNewsletter';

interface Props {
  setShowThankYou: (item: boolean) => void;
}

const Footer: React.FC<Props> = ({ setShowThankYou }) => {
  const [email, setEmail] = useState<string>('');
  const [checked, setChecked] = useState<boolean>(false);
  const [termsAlert, setTermsAlert] = useState<boolean>(false);
  const { t } = useTranslation();
  
  const addEmail = () => {
    if (checked) {
      addEmailToList(email);
      sendEmailForNewsletter(email);
      setShowThankYou(true);
      setEmail('');
      setChecked(false);
    } else {
      setTermsAlert(true);
    }
  };




  return (
    <div className={styles.footerSection}>
      <div className={styles.innerContainer}>
        <div className={styles.sideInner}>
          <div className={styles.leftContainer}>
            <div className={styles.subscribeToNewsletter}>{t('footer.subscribeToNews')}</div>
            <div className={styles.secondaryNewsText}>{t('footer.upToDate')}</div>
            <div className={styles.emailAdressContainer}>
              <input
                placeholder={t('footer.email')}
                className={styles.emailAdress}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className={styles.sendButton} onClick={addEmail}>
              {t('footer.send')}
              </div>
            </div>
            <div className={styles.termeniCheckBox}>
              <input
                type="checkbox"
                id="termeni"
                name="termeni"
                value="Termeni"
                className={termsAlert ? styles.termeniCheckAlert : styles.termeniCheck}
                checked={checked}
                onClick={() => setChecked(!checked)}
                onInput={() => setTermsAlert(false)}
              />
              <label
                className={termsAlert ? styles.termeniLabelAlert : styles.termeniLabel}
                onClick={() => window.open('/gdpr', '_blank')}
              >
                {t('footer.conditions')}
              </label>
            </div>
          </div>
        </div>
        <div className={styles.sideInner}>
          <div className={styles.rightContainer}>
            <a target={'_blank'} href={t('footer.socialsLinks.instagram')}>
              <img className={styles.socialIcon} src={images.footer.instagramSocial} />
            </a>
            <a target={'_blank'} href={t('footer.socialsLinks.facebook')}>
              <img className={styles.socialIcon} src={images.footer.facebookSocial} />
            </a>
            <a target={'_blank'} href={t('footer.socialsLinks.airbnb')}>
              <img className={styles.socialIcon} src={images.footer.airbnbSocial} />
            </a>
            <a target={'_blank'} href={t('footer.socialsLinks.booking')}>
              <img className={styles.socialIcon} src={images.footer.bookingSocial} />
            </a>
          </div>
        </div>
        <div className={styles.middleFoot}>
          <ul className={styles.ulMenu}>
            <li className={styles.liItem}>
              <NavHashLink className={styles.HashLinkStyle} smooth to="#aboutus">
                {t('navMenu.aboutUs')}
              </NavHashLink>
            </li>
            <li className={styles.liItem}>
              <NavHashLink className={styles.HashLinkStyle} smooth to="#gallery">
                {t('navMenu.gallery')}
              </NavHashLink>
            </li>
            <li className={styles.liItem}>
              <NavHashLink className={styles.HashLinkStyle} smooth to="#offers">
                {t('navMenu.offers')}
              </NavHashLink>
            </li>
            <li className={styles.liItem}>
              <NavHashLink className={styles.HashLinkStyle} smooth to="#reviews">
                {t('navMenu.reviews')}
              </NavHashLink>
            </li>
            <li className={styles.liItem}>
              <NavHashLink className={styles.HashLinkStyle} smooth to="#contactus">
                {t('navMenu.location')}
              </NavHashLink>
            </li>
            <li className={styles.liItem}>
              <NavHashLink className={styles.HashLinkStyle} smooth to="#contactus">
                {t('navMenu.contactUs')}
              </NavHashLink>
            </li>
          </ul>
        </div>
        <div className={styles.sideInner}>
          <div className={styles.rightContainer}>
            <div className={styles.oasisName}>OASIS</div>
            <div className={styles.residenceName}>RESIDENCE</div>
          </div>
        </div>
      </div>
      <div className={styles.bottomCopyright}>
        <span className={styles.footerText}>
          {t('footer.text')}
        </span>
        <img className={styles.nmcpIcon} src={images.footer.nmcpLogo} />
      </div>
    </div>
  );
};

export default Footer;
