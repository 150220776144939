import React, { Component, Suspense, useState } from 'react';

import ResidenceLanding from '../components/ResidenceLanding';

import WelcomeBlock from '../blocks/WelcomeBlock';
import AboutUs from '../blocks/AboutUs';
import GalleryCarousel from '../blocks/GalleryCarousel';
import OffersBlock from '../blocks/OffersBlock';
import Reviews from '../blocks/Reviews';
import ContactUs from '../blocks/ContactUs';
import Footer from './../blocks/Footer';
import styles from './../Navigation/MainNavigation.module.scss';
import ScrollUp from '../components/ScrollUpComponent';
import Facilities from '../blocks/FacilitiesBlock';
import './MainNavigation.module.scss';


const MainNavigation = () => {
  const [showThankYou, setShowThankYou] = useState<boolean>(false)
  return (
    <Suspense fallback="-">
      <ResidenceLanding showThankYou={showThankYou} setShowThankYou={setShowThankYou}/>
      <WelcomeBlock />
      <Facilities />
      <ScrollUp />
      <div className={styles.pageContainer}>
        <AboutUs />
        <GalleryCarousel />
        <OffersBlock />
        <Reviews />
        <ContactUs />
        <Footer setShowThankYou={setShowThankYou}/>
      </div>
    </Suspense>
  );
};

export default MainNavigation;
