import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { Suspense, useEffect } from 'react';
import ReactGA from 'react-ga4';

import 'bootstrap/dist/css/bootstrap.min.css';

import MainNavigation from './Navigation/MainNavigation';
import ManualReservations from './blocks/ManualReservations';
import './App.css';
import PaymentSuccessfulPage from './components/PaymentSuccessfulPage';

import { TermsAndCond } from '../src/components/TermsAndCond';
import ReactPixel from 'react-facebook-pixel'
import conversionsApi from './functions/helpers';

ReactGA.initialize('G-2WGBH4M82T');
ReactGA.send('pageview');

function App() {
  const handleScroll = () => {
    const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;

    if (bottom) {
      ReactGA.event('User scrolled to bottom');
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true,
    });
    conversionsApi();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <Routes>
            <Route
              path="/manual"
              element={
                <Suspense fallback="-">
                  <ManualReservations />{' '}
                </Suspense>
              }
            ></Route>
            <Route
              path="/gdpr"
              element={
                <div
                  style={{ marginLeft: '20px', marginRight: '20px', textAlign: 'justify' }}
                  dangerouslySetInnerHTML={TermsAndCond()}
                />
              }
            ></Route>
            <Route
              path="/PaymentSuccessful"
              element={
                <Suspense fallback="-">
                  <PaymentSuccessfulPage />
                </Suspense>
              }
            ></Route>
            <Route path="/" element={<MainNavigation />}></Route>
          </Routes>
        </Router>
      </header>
    </div>
  );
}

export default App;
