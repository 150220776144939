import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getTempDates, reserveDate } from '../functions/firebase';
import ReactPixel from 'react-facebook-pixel';
import {
  createInvoiceIndividual,
  createInvoiceIndividualLive,
  createInvoiceOrganisation,
  createInvoiceOrganisationLive,
} from '../services/payments';
import images from '../data/images';
import styles from './../components/PaymentSuccessful.module.scss';

const PaymentSuccessfulPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const id = location.search.substring(1);
  const reserveDatesInCalendar = async () => {
    const reservationObject = await getTempDates(id);
    await reserveDate(
      reservationObject?.name,
      reservationObject?.email,
      reservationObject?.adress,
      reservationObject?.details,
      reservationObject?.checkInDates,
    );
   
    let discountDays = reservationObject?.numberOfDays;
    console.log(discountDays, 'discountDays')
    for (let i = 1; i <= reservationObject?.numberOfDays; i++) {
      if (i % 4 === 0) {
        discountDays = discountDays - 1;
      }
    }
    ReactPixel.fbq('track', 'Purchase', { value: 300 * discountDays, currency: 'RON' });

    process.env.REACT_APP_ENV === 'test'
      ? reservationObject?.type !== 'individual'
        ? await createInvoiceOrganisation(
          reservationObject?.companyName,
          discountDays,
          reservationObject?.checkInString,
          reservationObject?.countryPre,
          reservationObject?.address,
          reservationObject?.city,
          reservationObject?.county,
          reservationObject?.fiscalCode,
          reservationObject?.regNumber,
          reservationObject?.email,
        )
        : await createInvoiceIndividual(
          reservationObject?.name,
          discountDays,
          reservationObject?.checkInString,
          reservationObject?.countryPre,
          reservationObject?.address,
          reservationObject?.city,
          reservationObject?.county,
          reservationObject?.email,
        )
      : reservationObject?.type !== 'individual'
        ? await createInvoiceOrganisationLive(
          reservationObject?.companyName,
          discountDays,
          reservationObject?.checkInString,
          reservationObject?.countryPre,
          reservationObject?.address,
          reservationObject?.city,
          reservationObject?.county,
          reservationObject?.fiscalCode,
          reservationObject?.regNumber,
          reservationObject?.email,
        )
        : await createInvoiceIndividualLive(
          reservationObject?.name,
          discountDays,
          reservationObject?.checkInString,
          reservationObject?.countryPre,
          reservationObject?.address,
          reservationObject?.city,
          reservationObject?.county,
          reservationObject?.email,
        );
  };

  useEffect(() => {
    reserveDatesInCalendar();
  }, []);

  return (
    <div className={styles.container} style={{ marginTop: '10vh' }}>
      <img src={images.paymentComponent.smiley} className={styles.smiley} />
      <div className={styles.title}>{t('paymentComponent.title')}</div>
      <div>
        <div className={styles.details}>{t('paymentComponent.thankYou')}</div>
        <div className={styles.details}>{t('paymentComponent.invoice')}</div>
        <div className={styles.details}>{t('paymentComponent.please')}</div>
        <div className={styles.details}>{t('paymentComponent.contactUs')}</div>
      </div>
      <button
        className={styles.okButton}
        onClick={() =>
          process.env.REACT_APP_ENV === 'test'
            ? (window.location = 'https://test.oasisresidence.co/')
            : (window.location = 'https://oasisresidence.co/')
        }
      >
        <span className={styles.buttonsText}>{t('paymentComponent.ok')}</span>
      </button>
    </div>
  );
};

export default PaymentSuccessfulPage;
