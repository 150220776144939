import React, { useState, useEffect, useRef } from 'react';
import images from '../data/images';
import { useTranslation } from 'react-i18next';
import styles from './PopUpComponent.module.scss';
import { addEmailToList } from '../functions/firebase';
import { sendEmailForNewsletter } from '../services/emails';
import ThankYouNewsletter from './ThankYouNewsletter';

interface Props {
  showPopUp: boolean;
  setShowPopUp: (item: boolean) => void;
  setShowThankYou: (item: boolean) => void;
  showThankYou: boolean;
}

const PopUpComponent: React.FC<Props> = ({ setShowPopUp, showPopUp, setShowThankYou, showThankYou }) => {
  const [email, setEmail] = useState<string>('');
  const [checked, setChecked] = useState<boolean>(false);
  const { t } = useTranslation();
  const [termsAlert, setTermsAlert] = useState<boolean>(false);

  const addEmail = () => {
    if (checked) {
      addEmailToList(email);
      alert(t('popUpComponent.thankYou'));
      setTimeout(() => {
        setShowPopUp(false);
      }, 500);
      sendEmailForNewsletter(email);
      setShowThankYou(true);
    } else {
      setTermsAlert(true);
    }
  };

  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (showPopUp && ref.current && !ref.current.contains(e.target)) {
        setShowPopUp(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showPopUp]);

  return (
    <div className={styles.backdropBooking} onScroll={(e) => e.preventDefault()}>
      <div className={styles.popUpContainer} ref={ref}>
        <img className={styles.closeButton} src={images.booking.closeButton} onClick={() => setShowPopUp(false)} />
        <div className={styles.contentContainer}>
          <div className={styles.mainText}>{t('popUpComponent.passionateAbout')}</div>
          <div className={styles.secondaryText}>{t('popUpComponent.subscribeTo')}</div>
          <input
            placeholder={t('popUpComponent.email')}
            className={styles.emailAdress}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className={styles.subscribeButton} onClick={addEmail}>
          {t('popUpComponent.subscribe')}
          </div>
          <div className={styles.termeniCheckBox}>
            <input
              type="checkbox"
              id="termeni"
              name="termeni"
              value="Termeni"
              className={termsAlert ? styles.termeniCheckAlert : styles.termeniCheck}
              checked={checked}
              onClick={() => (setChecked(!checked), setTermsAlert(false))}
            />
            <label
              className={termsAlert ? styles.termeniLabelAlert : styles.termeniLabel}
              onClick={() => window.open('/gdpr', '_blank')}
            >
              {t('popUpComponent.termsAndConditions')}
            </label>
          </div>
          {termsAlert ? <div className={styles.pleaseSelect}>{t('popUpComponent.pleaseCheck')}</div> : <div></div>}
        </div>
      </div>
    </div>
  );
};

export default PopUpComponent;
