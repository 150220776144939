import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

import HeaderMessage from '../components/HeaderMessage';
import CardComponent from '../components/CardComponent';
import TheSeparator from '../components/TheSeparator';
import images from './../data/images';

import styles from './OffersBlock.module.scss';
import OnePriceMiddleComponent from '../components/OnePriceMiddleComponent';

const OffersBlock = () => {
  const { t } = useTranslation();

  return (
    <>
      <TheSeparator anchorID="offers" />
      <div>
        <div className={styles.offersWrap}>
          <HeaderMessage headTitle={t('offersSection.headerTitle')} headDescription={t('offersSection.smallDescription')} />
          <div className={styles.cardsWrapper}>
            <div className={styles.photoSider}>
              <img src={images.offers.leftCardBoard} className={` ${styles.imageDimensions} ${styles.leftShadow}`} />
            </div>
            <OnePriceMiddleComponent />
            <div className={styles.photoSider}>
              <img src={images.offers.rightCardBoard} className={` ${styles.imageDimensions} ${styles.rightShadow}`} />
            </div>
          </div>
        </div>

        <div className={styles.calltoActions}>
          <button className={styles.airbnbStyle} onClick={() => window.open(t('offersSection.airBnbUrl'), '_blank')}>
            <img className={styles.bookingIcon} src={images.offers.airbnb} />
          </button>

          <button className={styles.bookNative}>
            <NavHashLink to="#" style={{ textDecoration: 'none' }}>
              <span className={styles.buttonText}>{t('offersSection.actionButton')}</span>
            </NavHashLink>
          </button>

          <button className={styles.bookingStyle} onClick={() => window.open(t('offersSection.bookingUrl'), '_blank')}>
            <img className={styles.bookingIcon} src={images.offers.bookingcom} />
          </button>
        </div>
      </div>
    </>
  );
};

export default OffersBlock;
